import { Injectable, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { config } from '@env/config';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { InformationService } from '@services/information.service';
import { WebAppLabel } from '@models/web-app-label';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export abstract class AbstractWebAppLabelComponent implements OnInit {

  infoLabels: WebAppLabel[];
  selectedInfoLabel: WebAppLabel;
  selectedLang: string;
  additionalInfoLabels: WebAppLabel[];
  additionalSelectedInfoLabel: WebAppLabel;

  constructor(protected _changeDetectorRef: ChangeDetectorRef, 
    protected translate: TranslateService, 
    protected informationService: InformationService,
    protected sanitizer: DomSanitizer,
    @Inject(String) protected _code: string){
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.selectedLang = event.lang;
            this.changeSelectedInfoLabel();
            this._changeDetectorRef.detectChanges();
          })

        if (this.translate.currentLang) {
            this.selectedLang = this.translate.currentLang.toUpperCase();
        } else if (this.translate.defaultLang) {
            this.selectedLang = this.translate.defaultLang.toUpperCase();
        }
        if (this.selectedLang) {
            this.informationService.getInformation(this._code).subscribe(res => {
                this.infoLabels = res;
                this.changeSelectedInfoLabel();
            })
            if (this._code === config.hintLoopCode) {
              this.informationService.getInformation(config.hintLoopCyclesCode).subscribe(res => {
                this.additionalInfoLabels = res;
                this.changeSelectedInfoLabel();
            })
            }
        }
  }

  ngOnInit() {
  }

  changeSelectedInfoLabel() {
    if (this.selectedLang && this.infoLabels) {
      this.infoLabels.forEach(o => {
        if (o.languageCode === this.selectedLang.toUpperCase()) {
          this.selectedInfoLabel = o;
        }
      })
    }
    if (this.selectedLang && this.additionalInfoLabels) {
      this.additionalInfoLabels.forEach(o => {
        if (o.languageCode === this.selectedLang.toUpperCase()) {
          this.additionalSelectedInfoLabel = o;
        }
      })
    }
  }
  
}