import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { config, urls } from '@env/config';
import { MeasurementFilter } from '@models/measurement-filter';
import { Measurement } from '@models/measurement';
import { MarkersRequest } from '@models/markers-request';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  protected httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor (private http: HttpClient, private datepipe: DatePipe) { }

    getMarkers( filter: MeasurementFilter): Observable<Measurement[]> {
      let requestParams = new HttpParams();
      if (filter.periodFrom != undefined) {
        requestParams = requestParams.set("from", this.datepipe.transform(filter.periodFrom, 'yyyy-MM-dd')!);
      }
      
      if (filter.periodTo != undefined) {
        requestParams = requestParams.set("to", this.datepipe.transform(filter.periodTo, 'yyyy-MM-dd')!);
      }
  
      if (filter.providerId != undefined) {
        requestParams = requestParams.set("provider", filter.providerId.toString());
      }
  
      if (filter.regionCodes && filter.regionCodes.length !== 0) {
        requestParams = requestParams.set("region", filter.regionCodes.toString());
      }
  
      if (filter.networkType != undefined) {
        requestParams = requestParams.set("network", filter.networkType);
      }
  
      return this.http.get<any>(config.BASE_WS_URL + config.public + config.filterPublicMap, {
        params: requestParams
          }).pipe(
            timeout(config.timeout)
        );
    }

    getMarker(markerRequest: MarkersRequest): Observable<any> {
      return this.http.post<any>(urls.markerInfo, markerRequest, this.httpOptions).pipe(
        timeout(config.timeout)
      );
    }
}
