import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { config } from '@env/config';
import { WebAppLabel } from '@models/web-app-label';

@Injectable({
    providedIn: 'root'
  })
  export class InformationService {

    constructor(private http: HttpClient){}

    getInformation(code: string): Observable<WebAppLabel[]> {
        const url = `${config.BASE_WS_URL + config.public + config.webAppLabels}/${code}`;

        return this.http.get<WebAppLabel[]>(url).pipe(timeout(config.timeout));
    }
  }