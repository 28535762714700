import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { timeout } from 'rxjs/operators';

import { SingleDataSet, Label } from 'ng2-charts';

import { config } from '@env/config';
import { SortingPaging } from '@app/util/sorting-paging';
import { MeasurementFilter } from '@models/measurement-filter';
import { Observable } from 'rxjs';
import { Page } from '@app/util/page';
import { Test } from '@models/test';
import { DatePipe } from '@angular/common';
import { Measurement } from '@models/measurement';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private translate:TranslateService,
    private http: HttpClient, 
    private datepipe: DatePipe
    ) { 
    translate.get('details.downloadSpeed').subscribe((text:string) => {});
  }

   public getHistory(uuid: string) {
    const req = { "language": "de", "timezone": "Europe/Sofia",  "uuid": uuid};

    return this.http.post<any>(config.baseAddress + config.controlServerPath + config.historyPath, req).pipe(timeout(config.timeout));
   }

   public getTestDetail(open_test_uuid: string, statisticServerPath: string) {
    return this.http.get<any>(statisticServerPath + config.openTestsPath + "/" + open_test_uuid).pipe(timeout(config.timeout));
   }


   public drawSingleSpeedCurve(data, dataSet: SingleDataSet, labels: Label[]) {
    //set width for flot
    // $(target + " .speed-curve-graph-" + phase).css("width",$(target + " .speed-curve-graph-" + phase).width() + "px");
    // var tableHtml = "";

    //smoothing-factor for exponential smoothing
    var alpha = 0.7;
    var conAlpha = 1-alpha;
    var minInterval = 175; //minimal difference in ms between data points
    var noTransferThreshold = 1400; //ms for which no transfer has to take place to be drawn as a gap in the graph
      
    //get data points from json-response from the controlserver  
    var previousSpeed=data[0].bytes_total/data[0].time_elapsed;
    var previousData = 0;
    var previousTime = 0;
    // var dDownload = [];
    
    //"preprocess" - if there are large gaps, fill them with artificial data points (#1117)
    var nData = [];
    for (var i=0;i<data.length;i++) {
        var timeDifference = data[i].time_elapsed - previousTime;
        if (timeDifference > (noTransferThreshold) && i > 0) {
            
            nData.push({
                time_elapsed: (data[i-1].time_elapsed + minInterval),
                bytes_total: (data[i-1].bytes_total)
            });
             nData.push({
                time_elapsed: (data[i-1].time_elapsed + 2*minInterval),
                bytes_total: (data[i-1].bytes_total)
            });
            nData.push({
                time_elapsed: (data[i].time_elapsed - minInterval),
                bytes_total: (data[i-1].bytes_total)
            });
        }
        nData.push(data[i]);
        previousTime = data[i].time_elapsed;
    }
    
    data = nData;
    previousTime = 0;
    
    for (var i = 0; i < data.length; ++i) {        
        var dataDifference = data[i].bytes_total - previousData;
        var timeDifference = data[i].time_elapsed - previousTime;
        
        //only one point every 250 ms or data is way too late (120 sek)
        if (timeDifference < minInterval || data[i].time_elapsed > 120000) {
            continue;
        }
        
        var speed = dataDifference / timeDifference;
        if (speed > 0) { //no interpolation in a gap
            speed = speed*alpha + previousSpeed*conAlpha;
        }
        previousSpeed = speed;
        previousData = data[i].bytes_total;
        previousTime = data[i].time_elapsed;
        //do it logarithmic
        speed =  Math.log(speed/125) / Math.LN10; //bytes/s -> 
        dataSet.push(Math.pow(10, speed)); //byte : 128 = kilobit; mbit/sec = kbit/ms       //third parameter -2: fill until bottom of graph
        let label = ((data[i].time_elapsed)/1000).toFixed(2);
        labels.push(label);
    }
  }
  findHistoryPageable(sortingPaging: SortingPaging, filter: MeasurementFilter): Observable<Page<Measurement>> {
    let requestParams = sortingPaging.getParams();

    if (filter.periodFrom != undefined) {
      requestParams = requestParams.set("from", this.datepipe.transform(filter.periodFrom, 'yyyy-MM-dd')!);
    }
    
    if (filter.periodTo != undefined) {
      requestParams = requestParams.set("to", this.datepipe.transform(filter.periodTo, 'yyyy-MM-dd')!);
    }

    if (filter.uuid != undefined) {
      requestParams = requestParams.set("clientUuid", filter.uuid!);
    }

    return this.http.get<any>(config.BASE_WS_URL + config.public + config.history, {
      params: requestParams
        }).pipe(
          timeout(config.timeout)
      );
  }
}
