import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HistoryComponent } from '@components/core/history/history.component';
import { HistoryDetailComponent } from '@components/core/history-detail/history-detail.component';
import { HomeComponent } from '@components/core/home/home.component';
import { InformationComponent } from '@components/core/information/information.component';
import { LoopTestComponent } from '@components/core/loop-test/loop-test.component';
import { MapComponent } from '@components/core/map/map.component';
import { OpenDataComponent } from '@components/core/open-data/open-data.component';
import { SingleTestComponent } from '@components/core/single-test/single-test.component';
import { StatisticsComponent } from '@components/core/statistics/statistics.component';
import { PrivacyPolicyComponent } from '@components/core/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from '@components/core/terms-of-use/terms-of-use.component';
import { FaqComponent } from '@components/core/faq/faq.component';
import { InfoPageComponent } from '@components/core/info-page/info-page.component';

const routes: Routes = [
  { path: ''                       , redirectTo: '/home'               , pathMatch: 'full' },
  { path: 'home'                   , component: HomeComponent          , data: {title: 'header.home'}},
  { path: 'test'                   , component: SingleTestComponent    , data: {title: 'header.startTest'} },
  { path: 'loop'                   , component: LoopTestComponent      , data: {title: 'home.loopMode'} },
  { path: 'map'                    , component: MapComponent           , data: {title: 'home.map'} },
  { path: 'statistics'             , component: StatisticsComponent    , data: {title: 'header.statistics'}},
  { path: 'search'                 , component: OpenDataComponent      , data: {title: 'home.openData'}},
  { path: 'information'            , component: InformationComponent   , data: {title: 'home.Information'}},
  { path: 'history'                , component: HistoryComponent       , data: {title: 'home.history'}},
  { path: 'history/detailed-result', component: HistoryDetailComponent , data: {title: 'home.details'}},
  { path: 'privacy-policy'         , component: PrivacyPolicyComponent , data: {title: 'home.privacyPolicy'}},
  { path: 'terms-of-use'           , component: TermsOfUseComponent    , data: {title: 'home.termsOfUse'}},
  { path: 'faq'                    , component: FaqComponent           , data: {title: 'home.FAQ'}},
  { path: 'info-page'            , component: InfoPageComponent      , data: {title: 'home.Information'}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
