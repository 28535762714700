import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

//don`t rearrange these leaflet imports!!!
import * as L from 'leaflet';
import 'leaflet.heat/dist/leaflet-heat.js'
import { Map, MapOptions, tileLayer } from 'leaflet';

import { SingleDataSet, Label } from 'ng2-charts';

import { TranslateService } from '@ngx-translate/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { FileType } from '@app/enum/file-type.enum';
import { chartConfig } from '@env/config';
import { FileExport } from '@models/file-export';
import { Measurement } from '@models/measurement';
import { ParamVisibility } from '@models/param-visibility';
import { DetailObjAdditionalData } from '@models/detail-object-additional-data';
import { QOSPageDetail } from '@models/qos/qos-page-detail';
import { QOSDetail } from '@models/qos/qos-detail';
import { QOSPageMeasurement } from '@models/qos/qos-page-measurement';
import { ExportService } from '@services/export.service';
import { HistoryService } from '@services/history.service';
import { MeasurementService } from '@services/measurement.service';
import { SettingsService } from '@services/settings.service';
import { ExportParams } from '@models/export-params';
import { HttpResponse } from '@angular/common/http';

  const myIcon = L.icon({
    iconUrl: './assets/img/marker.png'
  });

@Component({
  selector: 'app-history-detail',
  templateUrl: './history-detail.component.html',
  styleUrls: ['./history-detail.component.css']
})
export class HistoryDetailComponent implements OnInit {

  measurement!: Measurement;
  email: string;
  selectedLang: string;
  urlStatisticsServer: string;

  test_uuid: string;
  additionalData: DetailObjAdditionalData = new DetailObjAdditionalData();
  
  qos!: QOSDetail;
  qosDetails: QOSPageDetail[];
  qosParams: ParamVisibility[];

  map: Map;
  mapOptions: MapOptions;

  downloadChartData: SingleDataSet = [];
  downloadChartLabels: Label[] = [];
  uploadChartData: SingleDataSet = [];
  uploadChartLabels: Label[] = [];
  pingBarChartData: SingleDataSet = [];
  pingBarChartLabels: Label[] = [];

  downloadData = [
    {
      data: this.downloadChartData,
      backgroundColor: [
        chartConfig.downloadBackgroundColor
      ],
      borderColor: [
        chartConfig.downloadBackgroundColor
      ],
      pointBackgroundColor: [
        chartConfig.downloadBackgroundColor
      ],
    }
  ];

  uploadData = [
    {
      data: this.uploadChartData,
      backgroundColor: [
        chartConfig.uploadBackgroundColor
      ],
      borderColor: [
        chartConfig.uploadBackgroundColor
      ],
      pointBackgroundColor: [
        chartConfig.uploadBackgroundColor
      ],
    }
  ];
  
  chartOptions = chartConfig.chartOptions;
  pingBarOptions = chartConfig.pingBarOptions;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datepipe: DatePipe,
    private historyService: HistoryService,
    private measurementService: MeasurementService,
    private translate: TranslateService,
    private exportService: ExportService,
    private snackBar: MatSnackBar,
    private settingsService: SettingsService
  ) {
   }

  ngOnInit(): void {

    this.settingsService.getSettings("", false).subscribe(res => {
      if (res.settings && res.settings[0] && res.settings[0].urls.url_statistic_server) {
        this.urlStatisticsServer = res.settings[0].urls.url_statistic_server;
      }
    })
    this.measurementService.email.subscribe(result => {
      this.email = result;
    });

    this.route.queryParams.subscribe(params => {
       this.test_uuid = params['id'];
    });
    
    this.initializeMapOptions();
    this.getData();
        
      }
      
  getData(): void {
    this.measurementService.getTest(this.test_uuid).subscribe(res => {
      this.measurement = res;
      if (this.measurement) {
        if (this.email) {
          this.sendMail();
          this.measurementService.changeEmail(null);
        }
        if (this.measurement.geoLat) {
          this.map.setView([this.measurement.geoLat, this.measurement.geoLong]);
          let marker = L.marker([this.measurement.geoLat, this.measurement.geoLong], {icon: myIcon});
          marker.addTo(this.map)
        }
        if(this.measurement.openTestUuid) {
          this.measurementService.getVisibleQosParams().subscribe(res => {
            this.qosParams = res;
            if (this.qosParams && this.qosParams.length > 0) {
              this.measurementService.findQOS(this.measurement.openTestUuid)
              .subscribe(response => {
                this.qos = response;
                if (this.qos && this.qos.testresultdetail) {
                  this.fetchQOSData();
                }
               });
            }
          });

          if (this.urlStatisticsServer) {

            this.historyService.getTestDetail(this.measurement.openTestUuid, this.urlStatisticsServer).subscribe(response => {
              this.additionalData = response;
              this.additionalData.speed_curve = response.speed_curve;
              if (this.measurement) {
                if (this.measurement.speedDownload) {
                  this.loadDownloadData();
                }
                if (this.measurement.speedUpload) {
                  this.loadUploadData();
                }
                if (this.measurement.ping) {
                  this.loadPingBarData();
                }
              }
            });
          }
        }
      } else {
        this.router.navigate(['history']);
        this.openSnackBar(this.translate.instant("details.measurementNotFound"));
      }
    })
  }

  getCityName(): string {
    if (this.translate.currentLang) {
      this.selectedLang = this.translate.currentLang.toUpperCase();
    } else if (this.translate.defaultLang) {
      this.selectedLang = this.translate.defaultLang.toUpperCase();
    }

    if (this.selectedLang === 'BG') {
      return this.measurement.city;
    } else {
      return this.measurement.cityLat;
    }

    return '';
  }

  getRegionName(): string {
    if (this.translate.currentLang) {
      this.selectedLang = this.translate.currentLang.toUpperCase();
    } else if (this.translate.defaultLang) {
      this.selectedLang = this.translate.defaultLang.toUpperCase();
    }

    if (this.selectedLang === 'BG') {
      return this.measurement.region;
    } else {
      return this.measurement.regionLat;
    }

    return '';
  }

  //QOS
  fetchQOSData() {
    this.qosDetails = [];

    this.qosDetails.push(this.getDNSData('dns', ['dns']));
    this.qosDetails.push(this.getQOS('transparent', ['non_transparent_proxy'], ['ntp.success']));
    // this.qosDetails.push(this.getQOS('ports', ['tcp', 'udp'], ['tcp.success', 'udp.success']));
    this.qosDetails.push(this.getQOS('udp', ['udp'], ['udp.success']));
    this.qosDetails.push(this.getQOS('tcp', ['tcp'], ['tcp.success']));
    this.qosDetails.push(this.getAudioVideo());
    this.qosDetails.push(this.getQOS('web' ,['website'], ['website.200']));
    this.qosDetails.push(this.getQOS('proxy' ,['non_transparent_proxy', 'http_proxy'], ['ntp.success', 'http.success']));
    this.qosDetails.push(this.getVoip('voip', ['voip']));
    this.qosDetails.push(this.getQOS('traceroute' ,['traceroute_masked', 'traceroute'], ['traceroute.success']));
  }

  getDNSData(type: string, types: string[]): QOSPageDetail {
    var detail = new QOSPageDetail(type);
    if (!this.qos.testresultdetail) return detail;

    var results = this.qos.testresultdetail.filter(function (element) {
      return types.includes(element.test_type);
    });

    results.forEach(function (element) {
      detail.success_count += element.success_count;
      detail.failure_count += element.failure_count;
      if (element.failure_count > 0) {
        var item = new QOSPageMeasurement();
        item.description = element.test_summary + '<br/>';
        if (element.test_result_key_map !== undefined) {
          for (const [key, value] of Object.entries(element.test_result_key_map)) {
            if ((key === 'dns.success' || key === 'dns.unknowndomain.success') && value === 'ok') {
              item.success = true;
            } else {
              item.success = false;
            }
          }
        }
        detail.items.push(item);
      }
    });
    return detail;
  }

  getQOS(type: string, types: string[], successDesc: string[]): QOSPageDetail {
    var detail = new QOSPageDetail(type);
    if (!this.qos.testresultdetail) return detail;

    var results = this.qos.testresultdetail.filter(function (element) {
      return types.includes(element.test_type);
    });

    results.forEach(function (element) {
      detail.success_count += element.success_count;
      detail.failure_count += element.failure_count;
      if (element.failure_count > 0) {
        var item = new QOSPageMeasurement();
        item.description = element.test_summary + '<br/>';
        if (element.test_result_key_map !== undefined) {
          for (const [key, value] of Object.entries(element.test_result_key_map)) {
            if (successDesc.includes(key)) {
              item.success = true;
            } else {
              item.success = false;
            }
          }
        }
        detail.items.push(item);
      }
    });
    return detail;
  }

  getAudioVideo(): QOSPageDetail {
    var detail = new QOSPageDetail('media');
    if (!this.qos.testresultdetail) return detail;
 
    var results = this.qos.testresultdetail.filter(function (element) {
      return (element.test_type === 'tcp' || element.test_type === 'udp') && element.test_summary.includes('audio and visual');
    });

    results.forEach(function (element) {
      detail.success_count += element.success_count;
      detail.failure_count += element.failure_count;

      var item = new QOSPageMeasurement();
      for (const [key, value] of Object.entries(element.test_result_key_map)) {
        if ('tcp.success'.includes(key) || 'udp.success'.includes(key)) {
          item.success = true;
        } else {
          item.success = false;
        }
      }
      item.description = element.test_summary + '<br/>';
      detail.items.push(item);
    });
    return detail;
  }

  getVoip(type: string, types: string[]): QOSPageDetail {
    var detail = new QOSPageDetail(type);
    if (!this.qos.testresultdetail) return detail;

    var results = this.qos.testresultdetail.filter(function (element) {
      return types.includes(element.test_type);
    });

    results.forEach(function (element) {
      detail.success_count += element.success_count;
      detail.failure_count += element.failure_count;
      
      var item = new QOSPageMeasurement();
      if (detail.success_count > 0 && detail.failure_count === 0) {
        item.success = true;
      } else {
        item.success = false;
      }
      item.description = element.test_summary + '<br/>';
      detail.items.push(item);
    });
    return detail;
  }

  getQOSData(type: string): QOSPageDetail | undefined {
    if (!this.qosDetails) return undefined;

    return this.qosDetails.filter(function (element) {
      return element.type === type;
    })[0];
  }

  getTracerouteHops(): any {
    if (!this.qosDetails || !this.qos.testresultdetail) return undefined;

    var testResultDetail = this.qos.testresultdetail.filter(function (element) {
      return element.test_type === 'traceroute_masked';
    })[0];

    if (testResultDetail && testResultDetail.result) return testResultDetail.result.traceroute_result_hops;
    else return undefined;
  }

  getQOSStatus(status: boolean): string {
    if (status === undefined) return 'help';

    return status ? 'check_circle' : 'clear';
  }

  getParamVisibilityByCode(code: string): boolean {
    let result = false;
    if (this.qosParams) {
      this.qosParams.forEach(param => {
        if (param.code === code) {
          result = true;
          return;
        }
      });
    }
    return result;
  }

  //Charts
  loadDownloadData() {
    this.historyService.drawSingleSpeedCurve(this.additionalData.speed_curve.download, this.downloadChartData, this.downloadChartLabels);
  }

  loadUploadData() {
    this.historyService.drawSingleSpeedCurve(this.additionalData.speed_curve.upload, this.uploadChartData, this.uploadChartLabels);
  }

  loadPingBarData() {
    let start = this.additionalData.speed_curve.ping[0].time_elapsed;
    this.additionalData.speed_curve.ping.forEach(o => {
      this.pingBarChartData.push(Math.round(o.ping_ms));
      let label = ((o.time_elapsed - start)/1000).toFixed(2);
      this.pingBarChartLabels.push(label !== null ? label.toString() : '');
    });
  }
  
  //Map
  onMapReady(map: Map) {
    this.map = map;
  }

  private initializeMapOptions() {
    this.mapOptions = {
      attributionControl: false,
      zoom: 16,
      layers: [
        tileLayer(
          'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {
            maxZoom: 18,
            minZoom:14
          })
      ],
    };
  }

  //Export
  exportToPdf(): void {
    if (this.measurement && this.measurement.openTestUuid && this.urlStatisticsServer) {
      let exportParams = new ExportParams();
      exportParams.lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
      exportParams.openTestUuid = this.measurement.openTestUuid;
      exportParams.sendMail = false;
      this.exportService.exportFile(exportParams, this.urlStatisticsServer, FileType.PDF,
         this.translate.instant("reports.measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), false);
    }
    // this.exportService.exportMeasurementAsPdfFile(this.prepareDataToExport(), this.getHeading(),
    //         this.translate.instant("measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), this.getHeader());
  }

  exportToExcel(): void {
    if (this.measurement && this.measurement.openTestUuid && this.urlStatisticsServer) {
      let exportParams = new ExportParams();
      exportParams.lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
      exportParams.openTestUuid = this.measurement.openTestUuid;
      exportParams.sendMail = false;
      this.exportService.exportFile(exportParams, this.urlStatisticsServer, FileType.XLXS,
        this.translate.instant("reports.measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), false);
    }
    // this.exportService.exportMeasurementAsExcelFile(this.prepareDataToExport(), this.getHeading(),
    //         this.translate.instant("reports.measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), [[this.getHeader()]]);
  }

  exportToCsv(): void {
    if (this.measurement && this.measurement.openTestUuid && this.urlStatisticsServer) {
      let exportParams = new ExportParams();
      exportParams.lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
      exportParams.openTestUuid = this.measurement.openTestUuid;
      exportParams.sendMail = false;
      this.exportService.exportFile(exportParams, this.urlStatisticsServer, FileType.CSV,
        this.translate.instant("reports.measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), false);
    }
    // this.exportService.exportMeasurementAsCsvFile(this.prepareDataToExport(), this.getHeading(),
    //         this.translate.instant("reports.measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), [[this.getHeader()]]);
  }

  // getHeader(): string {
  //   return this.translate.instant("reports.measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! });
  // }

  // getHeading() : string[][] {
  //   return [[this.translate.instant("param"), 
  //             this.translate.instant("value")]]
  // }

  // prepareDataToExport(): Array<any[]> {
  //   let body: Array<any[]> = [];
  //   let exportArray: any[] = this.exportService.prepareDataToExport(this.measurement, this.translate);
  //   if (this.qosDetails) {
  //     exportArray = exportArray.concat(this.prepareQOSDataToExport());
  //   }
  //   body.push(exportArray);
  //   return body;
  // }

  // prepareQOSDataToExport(): string[] {
  //   let exportArray: any[] = [];
  //   let filter;

  //   if (this.getParamVisibilityByCode('availability_of_connectivity') && this.getQOSData('transparent')?.containsItems()) {
  //     filter = [
  //       this.translate.instant("details.availability_of_connectivity"), 
  //       this.getQOSData('transparent')!.getItems().replace(/<br\s*\/?>/gi, '\n')
  //     ];
  //     exportArray.push(filter);
  //   }

  //   if (this.getParamVisibilityByCode('dns') && this.getQOSData('dns')?.containsItems()) {
  //     filter = [
  //       this.translate.instant("details.dns"), 
  //       this.getQOSData('dns')!.getItems().replace(/<br\s*\/?>/gi, '\n')
  //     ];
  //     exportArray.push(filter);
  //   }

  //   if (this.getParamVisibilityByCode('proxy')) {
  //     filter = [
  //       this.translate.instant("details.proxy"),
  //       this.translate.instant("export_qos." + this.getQOSStatus(this.measurement.proxy))
  //     ];
  //     exportArray.push(filter);
  //   }

  //   if (this.getQOSData('proxy')?.containsItems()) {
  //     filter = [
  //       '', 
  //       this.getQOSData('proxy')!.getItems().replace(/<br\s*\/?>/gi, '\n')
  //     ];
  //     exportArray.push(filter);
  //   }

  //   if (this.getParamVisibilityByCode('web')) {
  //     filter = [
  //       this.translate.instant("details.webPerformance"), 
  //       this.translate.instant("export_qos." + this.getQOSStatus(this.measurement.webPerformance))
  //     ];
  //     exportArray.push(filter);

  //     if (this.getQOSData('web')?.containsItems()) {
  //       filter = [
  //         '', 
  //         this.getQOSData('web')!.getItems().replace(/<br\s*\/?>/gi, '\n')
  //       ];
  //       exportArray.push(filter);
  //     }
  //   }

  //   if (this.getParamVisibilityByCode('media')) {
  //     filter = [
  //       this.translate.instant("details.videoAndAudio"), 
  //       this.translate.instant("export_qos." + this.getQOSStatus(this.measurement.videoAndAudio))
  //     ];
  //     exportArray.push(filter);

  //     if (this.getQOSData('media')?.containsItems()) {
  //       filter = [
  //         '', 
  //         this.getQOSData('media')!.getItems().replace(/<br\s*\/?>/gi, '\n')
  //       ];
  //       exportArray.push(filter);
  //     }
  //   }

  //   if (this.getParamVisibilityByCode('voip')) {
  //     filter = [
  //       this.translate.instant("details.voip"), 
  //       this.translate.instant("export_qos." + this.getQOSStatus(this.measurement.voip))
  //     ];
  //     exportArray.push(filter);
  
  //     if (this.getQOSData('voip')?.containsItems()) {
  //       filter = [
  //         '', 
  //         this.getQOSData('voip')!.getItems().replace(/<br\s*\/?>/gi, '\n')
  //       ];
  //       exportArray.push(filter);
  //     }
  //   }

  //   filter = [
  //     this.translate.instant("details.traceroute"), 
  //     this.getTracerouteHops()
  //   ];
  //   exportArray.push(filter);

  //   if (this.getQOSData('ports')?.containsItems()) {
  //     filter = [
  //       this.translate.instant("details.blockedPorts"), 
  //       this.getQOSData('ports')!.getItems().replace(/<br\s*\/?>/gi, '\n')
  //     ];
  //     exportArray.push(filter);
  //   }

  //   return exportArray;
  // }

  // Dialogs
  openSnackBar(message: string) {
    this.snackBar.open(message, this.translate.instant("close"), {
      duration: 5 * 1000,
    });
  }

  // Mailing
  private sendMail() {
    if (this.measurement && this.measurement.openTestUuid && this.urlStatisticsServer) {
      let exportParams = new ExportParams();
      exportParams.lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
      exportParams.openTestUuid = this.measurement.openTestUuid;
      exportParams.sendMail = true;
      exportParams.recipient = this.email;
      this.exportService.sendMail(exportParams, this.urlStatisticsServer, FileType.PDF, this.translate.instant("measurementDetails"));
    }
    // let fileData = this.exportService.buildMeasurementPdfFile(this.prepareDataToExport(), this.getHeading(),
    // this.translate.instant("measurementDetails"), this.getHeader());
    // this.send(this.email!, fileData.output(), FileType.PDF);
  }

  // private send(email: string, fileData: string, mimeType: FileType) {
  //   if (this.translate.currentLang) {
  //     this.selectedLang = this.translate.currentLang.toUpperCase();
  //   } else if (this.translate.defaultLang) {
  //     this.selectedLang = this.translate.defaultLang.toUpperCase();
  //   }
  //   this.measurementService.sendMail(new FileExport(email, btoa(fileData), mimeType, this.selectedLang.toLocaleUpperCase()))
  //       .subscribe(details => {
  //         this.openSnackBar(this.translate.instant("messages.emailSent"));
  //   });
  // }


}
