import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yn-dialog',
  templateUrl: './yn-dialog.component.html',
  styleUrls: ['./yn-dialog.component.css']
})
export class YnDialogComponent implements OnInit {

  dialogTitle!: string;
  dialogText!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: YnDialogComponent) { }

  ngOnInit(): void {
    this.dialogTitle = this.data.dialogTitle;
    this.dialogText = this.data.dialogText;
  }

}
