import { Component, OnInit, ComponentFactoryResolver, Injector, ChangeDetectorRef } from '@angular/core';

import * as L from 'leaflet';
import { latLng, Map, MapOptions, tileLayer } from 'leaflet';
import 'leaflet.heat/dist/leaflet-heat.js'

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

import { TranslateService } from '@ngx-translate/core';

import { AppDateAdapter, APP_DATE_FORMATS } from '@app/util/format-datepicker';
import { MeasurementFilter } from '@models/measurement-filter';
import { Measurement } from '@models/measurement';
import { Provider } from '@models/provider';
import { Region } from '@models/region';
import { LocationService } from '@services/location.service';
import { MapService } from '@services/map.service';
import { MeasurementService } from '@services/measurement.service';
import { ProviderService } from '@services/provider.service';
import { PopupComponent } from '../popup/popup.component';
import { AbstractWebAppLabelComponent } from '@components/abstract/abstract-web-app-label-component';
import { InformationService } from '@services/information.service';
import { DomSanitizer } from '@angular/platform-browser';
import { config, urls } from '@env/config';
import { MarkersRequest } from '@models/markers-request';
import { Coordinates } from '@models/coordinates';
import { Filter } from '@models/map-filter';
import { MapOption } from '@models/map-options';
import { Classification } from '@models/classification';
import { Capabilities } from '@models/capabilities';
import { MeasurementMarker } from '@models/measurement-marker';
import { DatePipe } from '@angular/common';

const myIcon = L.icon({
  iconUrl: './assets/img/marker.png'
});
const maxZoom = 21;
const showPoints = 13;
const dateFormat = 'yyyy-MM-dd';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent extends AbstractWebAppLabelComponent implements OnInit {
  filter = new MeasurementFilter();
  providers: Provider[];
  regions: Region[];
  networkTypes: string[];
  measurements: Measurement[];
  markers: MeasurementMarker[];

  map: Map;
  mapOptions: MapOptions;
  points= [];
  heatLayer: any;
  controlScale: any;
  selectedLang: string;
  pointsLayer: L.TileLayer;
  heatmapLayer: L.TileLayer;
  marker: L.Marker;
  markerRequest: MarkersRequest;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    protected translate: TranslateService,
    private mapService: MapService,
    private measurementService: MeasurementService, 
    private providerService: ProviderService,
    private locationService: LocationService,
    private datepipe: DatePipe,
    protected _changeDetectorRef: ChangeDetectorRef, 
    protected informationService: InformationService,
    protected sanitizer: DomSanitizer,
    ) { 
      super(_changeDetectorRef, translate, informationService, sanitizer, config.hintMapCode);
  }

  ngOnInit(): void {
    this.loadFilters();
    this.initializeMapOptions();
    // this.loadLayers();
    // this.loadData();
  }

  onMapReady(map: Map) {
    this.map = map;
    // this.heatmapLayer = L.tileLayer("https://test-rmbt.infosys.bg/RMBTMapServer/tiles/heatmap/{z}/{x}/{y}.png?null&statistical_method=0.5&period=180&map_options=mobile/download", {maxZoom: 19 });
    // this.pointsLayer = L.tileLayer("https://test-rmbt.infosys.bg/RMBTMapServer/tiles/points/{z}/{x}/{y}.png?null&statistical_method=0.5&period=180&map_options=mobile/download", {maxZoom: 19 });
    // this.loadLayers();
    this.loadData();
    let _this = this;
    this.map.on('zoomend',function(e){
      _this.clearMarkers();
      if (map.getZoom() > showPoints) {
        if (!map.hasLayer(_this.pointsLayer)) {
          map.addLayer(_this.pointsLayer);
        }
      } else if (map.getZoom() <= showPoints) {
        if (map.hasLayer(_this.pointsLayer)) {
          map.removeLayer(_this.pointsLayer);
        }
      }
    });

    this.map.on("click",<LeafletMouseEvent>(e) => {
      _this.clearMarkers();
      // let period = this.getDays();
      let from = this.filter.periodFrom ? this.datepipe.transform(this.filter.periodFrom, dateFormat) : '';
      let to = this.filter.periodTo ? this.datepipe.transform(this.filter.periodTo, dateFormat) : '';
      var regions = [];
      if (this.filter.regionCodes && this.filter.regionCodes.length > 0) {
        this.filter.regionCodes.forEach(region => {
          regions.push(region);
        });
      }
      let filter = new Filter(regions.length > 0 ? regions.join(',') : '' , from, to, this.filter.providerId ? this.filter.providerId.toString() : '', this.filter.networkType);
      let options = new MapOption();
      let capabilities = new Capabilities(new Classification(4));
      this.markerRequest = new MarkersRequest(new Coordinates(e.latlng.lat, e.latlng.lng, this.map.getZoom()), filter, 
        this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang, options, capabilities);
      this.mapService.getMarker(this.markerRequest).subscribe(res  => {
        if (res && res.measurements) {
          this.markers = res.measurements;
          this.markers.forEach(o => {
                  this.marker = L.marker([e.latlng.lat, e.latlng.lng], {icon: myIcon}).bindPopup(() => this.createCustomPopup(this.markers), {
                    closeButton: false
                  });
                  this.marker.addTo(this.map).openPopup();
                  this.points.push(this.marker);
          })
        }
      });
    });
  }

  private initializeMapOptions() {
    this.mapOptions = {
      attributionControl: false,
      center: latLng(42.75, 25.50),
      zoom: 8,
      layers: [
        tileLayer(
          'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {
            maxZoom: maxZoom,
          })
      ],
    };
  }

  private loadLayers() {
    let filters = this.getFilterParams();
    let params = filters !== '' ? '&' + filters : filters
    this.heatmapLayer = L.tileLayer(urls.heatLayer + params,
     { maxZoom: maxZoom});
    this.pointsLayer = L.tileLayer(urls.pointsLayer + params, 
    { maxZoom: maxZoom});
  }

  private getFilterParams(): string {
    var parts = [];
    if (this.filter) {
      if (this.filter.providerId && this.filter.providerId !== 0) {
        parts.push('provider' + '=' +  this.filter.providerId);
      }
      if (this.filter.networkType && this.filter.networkType != config.filterAll) {
        parts.push('technology' + '=' + this.filter.networkType);
      }
      if (this.filter.periodFrom) {
        parts.push('from' + '=' + this.datepipe.transform(this.filter.periodFrom, dateFormat));
      }
      if (this.filter.periodTo) {
        parts.push('to' + '=' + this.datepipe.transform(this.filter.periodTo, dateFormat));
      }
      if (this.filter.regionCodes && this.filter.regionCodes.length > 0) {
        var regions = [];
        var all = false;
        this.filter.regionCodes.forEach(region => {
          if (region === '0') {
            all = true;
          }
          regions.push(region);
        });
        if (regions.length > 0 && !all) {
          parts.push('region_code' + '=' + regions.join(','));
        }
      }
    }

    return parts.join('&');
  }

  // getDays(): string {
  //   if (this.filter.periodFrom || this.filter.periodTo) {

  //     let from = new Date();
  //     let to = new Date();
  //     if (this.filter.periodFrom) {
  //       from = this.filter.periodFrom;
  //     }
  //     if (this.filter.periodTo) {
  //       to = this.filter.periodTo;
  //     }
  
  //     return Math.floor((Date.UTC(to.getFullYear(), to.getMonth(), to.getDate())
  //     - Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()) ) /(1000 * 60 * 60 * 24)) + '';
  //   }

  //   return '';
  // }

  search() {
    this.clearMap();
    this.loadData();
  }

  clearMap() {
    this.map.removeLayer(this.heatmapLayer);
    if (this.map.hasLayer(this.pointsLayer)) {
      this.map.removeLayer(this.pointsLayer);
    }
    this.clearMarkers();
    // for (let i=0;i<this.points.length;i++) {
    //   this.map.removeLayer(this.points[i]);
    // }
    // this.map.removeLayer(this.heatLayer);
    // this.controlScale.remove();
    // this.points=[];
  }

  clearMarkers() {
    for (let i=0;i<this.points.length;i++) {
      this.map.removeLayer(this.points[i]);
    }
    this.points=[];
    if (this.markers) {
      this.markers = undefined;
    }
  }

  loadData() {
    this.loadLayers();
    this.map.addLayer(this.heatmapLayer);
    if (this.map.getZoom() > showPoints && !this.map.hasLayer(this.pointsLayer)) {
      this.map.addLayer(this.pointsLayer);
    }
      // this.mapService.getMarkers(this.filter).subscribe(res  => {
      //   let addressPoints = [];
      //   res.forEach(o => {
      //     if (o.geoLat && o.geoLong) {
      //       addressPoints.push([o.geoLat, o.geoLong]);
      //       let marker = L.marker([o.geoLat, o.geoLong], {icon: myIcon}).bindPopup(() => this.createCustomPopup(o)).openPopup();
      //       this.points.push(marker);
      //       marker.addTo(this.map)
      //     }
      //   });
      //         let newAddressPoints = addressPoints.map(function (p) { return [p[0], p[1]]; });
      //         this.heatLayer = (L as any).heatLayer(newAddressPoints, {radius: 75, blur: 40, gradient: {0.35: 'red', 0.65: 'lime', 1: 'red'}})
      //         this.heatLayer.addTo(this.map).redraw();
      //         this.controlScale = L.control.scale({imperial: false, maxWidth: 100});
      //         this.controlScale.addTo(this.map);
      // });
  }

  private createCustomPopup(markers: MeasurementMarker[]) { 
    const factory = this.componentFactoryResolver.resolveComponentFactory(PopupComponent);
    const component = factory.create(this.injector);

    component.instance.markers = markers;
    component.instance.testTime = this.getTranslation('details.testTime');
    component.instance.selectedLang = this.translate.currentLang ? this.translate.currentLang.toUpperCase() : this.translate.defaultLang.toUpperCase();

    component.changeDetectorRef.detectChanges();

    return component.location.nativeElement;
}

//   private createCustomPopup(obj: Measurement) { 
//     const factory = this.componentFactoryResolver.resolveComponentFactory(PopupComponent);
//     const component = factory.create(this.injector);

//     component.instance.test = obj;
//     component.instance.testTime = this.getTranslation('details.testTime');
//     component.instance.selectedLang = this.translate.currentLang ? this.translate.currentLang.toUpperCase() : this.translate.defaultLang.toUpperCase();

//     component.changeDetectorRef.detectChanges();

//     return component.location.nativeElement;
// }

getTranslation(wordYouNeedToTranslate: string): string {
  return this.translate.instant(wordYouNeedToTranslate);
}

  loadFilters(): void {

    this.providerService.findAll().subscribe(response => {
      this.providers = response;
      let all = new Provider();
      all.id = 0;
      all.name = config.filterAll;
      this.providers.push(all);
    });

    this.locationService.findAllRegions().subscribe(response => {
      this.regions = response;
      let all = new Region();
      all.code = '0';
      all.name = config.filterAll;
      this.regions.push(all);
    });

    this.measurementService.findAllNetworkTypes("map_filter").subscribe(response => {
      this.networkTypes = response;
      this.networkTypes.push(config.filterAll);
      // this.networkTypes.unshift(NetworkTypeEnum.FIXED);
    });
  }

 getRegionFilterName(region: Region): string {
  if (this.translate.currentLang) {
    this.selectedLang = this.translate.currentLang.toUpperCase();
  } else if (this.translate.defaultLang) {
    this.selectedLang = this.translate.defaultLang.toUpperCase();
  }

  if (this.selectedLang === 'BG') {
    return region.name;
  }

    // if (localStorage.getItem("lang") != null) {
    //   this.selectedLang = localStorage.getItem("lang")!.toUpperCase();
    //       if (this.selectedLang === 'BG') {
    //         return region.name;
    //       }
    // }
    return region.nameLat;
  }

  public networkComparison = function( option, value ) : boolean {
    if (option !== undefined && value !== undefined) {
      return option === value;
    } else {
      return false;
    }
  }

 public idComparison = function( option, value ) : boolean {
   if (option !== undefined && value !== undefined) {
     return +option === +value;
   } else {
     return false;
   }
 }

 public regionComparison = function( option, value ) : boolean {
   if (option !== undefined && value !== undefined) {
     return option === value;
   } else {
     return false;
   }
 }

 public getQOSStatus(value: boolean): string {
   if (value === undefined) return 'help';

   return value ? 'check_circle' : 'clear';
 }

  clear(): void {
    this.clearFilters();
    this.search();
  }

 public clearFilters() {
   if (this.filter !== undefined) this.filter.clear();
 }

}
