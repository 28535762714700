import { Coordinates } from './coordinates';
import { MapOption } from './map-options';
import { Filter } from './map-filter';
import { Capabilities } from './capabilities';

export class MarkersRequest {
    capabilities: Capabilities = null;
    coords: Coordinates = null;
    filter: Filter = null;
    language: string;
    options: MapOption = null;

    constructor(coords: Coordinates, filter: Filter, language: string, options: MapOption, capabilities: Capabilities) {
        this.coords = coords;
        this.filter = filter;
        this.language = language;
        this.options = options;
        this.capabilities = capabilities;
    }
}