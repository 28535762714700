import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { SettingsService } from '@services/settings.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  urlSafe: SafeResourceUrl;
  selectedLang: string = null;

  constructor(
    private sanitizer: DomSanitizer,
    private settingsService: SettingsService,
    private translate: TranslateService) {
      translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.setLanguage();
      });
  }

  ngOnInit(): void {
    this.settingsService.getSettings("", false).subscribe(res => {
      if (res.settings && res.settings[0]) {
        if (res.settings[0].urls.url_statistics) {
          let url = res.settings[0].urls.url_statistics;
          this.setSelectedLang();
          if (this.selectedLang !== null) {
            url = url + '?lang=' + this.selectedLang;
          }
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
      }
    })
  }

  setLanguage() {
    if (this.selectedLang === null) {
      this.setSelectedLang();
    } else {
      this.setSelectedLang();
      window.location.reload();
    }
  }

  setSelectedLang() {
    if (this.translate.currentLang) {
      this.selectedLang = this.translate.currentLang;
    } else if (this.translate.defaultLang) {
      this.selectedLang = this.translate.defaultLang;
    }
  }
}
