<h1 translate>home.Information</h1>
<div>
    <ul class="nav">
        <li routerLink="/information" translate>home.Information</li>
        <li routerLink="/privacy-policy" translate>home.privacyPolicy</li>
        <li routerLink="/terms-of-use" translate>home.termsOfUse</li>
        <li routerLink="/faq" translate>home.FAQ</li>
        <li ><a type="button" (click)="downloadGuide()" translate>home.guide</a></li>
    </ul>
<div></div>
