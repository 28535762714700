import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { timeout } from 'rxjs/operators';

import { config } from '@env/config';

@Injectable({
    providedIn: 'root'
  })
  export class SettingsService {

    constructor(private http: HttpClient){}

    getSettings(uuid: string, termsAccepted: boolean) {
        const req = { "language": "de", "type":"DESKTOP", "name":"Open-RMBT", "terms_and_conditions_accepted":termsAccepted, uuid: uuid};

        return this.http.post<any>(config.baseAddress + config.controlServerPath + config.settings, req).pipe(timeout(config.timeout));
    }
  }