import { Injectable, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { config } from '@env/config';
import { SettingsService } from '@services/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyPolicyDialogComponent } from '@components/core/privacy-policy-dialog/privacy-policy-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AbstractWebAppLabelComponent } from './abstract-web-app-label-component';
import { InformationService } from '@services/information.service';
import { DomSanitizer } from '@angular/platform-browser';

declare const Cookie: any;

@Injectable()
export abstract class AbstractTestComponent extends AbstractWebAppLabelComponent implements OnInit {

  clientUUID: string;
  termsAccepted: boolean = false;

  constructor(protected snackBar: MatSnackBar, protected settingsService: SettingsService, protected dialog: MatDialog, protected translate: TranslateService,
    protected _changeDetectorRef: ChangeDetectorRef, protected informationService: InformationService,
    protected sanitizer: DomSanitizer, @Inject(String) protected _code: string){
      super(_changeDetectorRef, translate, informationService, sanitizer, _code)
    this.clientUUID = this.getCookie(config.uuid_cookie_name);
    this.termsAccepted = this.getCookie(config.terms_cookie_name);
    // this.getClientUUID();
    if (!this.termsAccepted) {
      const dialogRef = this.dialog.open(PrivacyPolicyDialogComponent, {
        data: {dialogTitle: this.translate.instant("messages.pleaseConfirm"), dialogText: this.translate.instant("messages.confirmLocationAndIP")}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.termsAccepted = result;
          this.setCookie(config.terms_cookie_name, this.termsAccepted, config.exdays);
          this.getClientUUID();
        }
      }
      );
    } else {
      this.getClientUUID();
    }
  }

  ngOnInit() {
  }

  getClientUUID() {
    this.settingsService.getSettings(this.clientUUID, this.termsAccepted).subscribe(res => {
      if (res.settings && res.settings[0]) {
        if (res.settings[0].uuid) {
          this.clientUUID = res.settings[0].uuid;
          this.setCookie(config.uuid_cookie_name, this.clientUUID, config.exdays);
        }
      }
    })
  }
  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: config.snackbarDuration * 1000,
      panelClass: 'my-custom-snackbar'
    });
  }

  getCookie(cookie_name: string): any {
    return Cookie.getCookie(cookie_name);
  }

  setCookie(cookie_name, cookie_value, cookie_exseconds) {
    Cookie.setCookie(cookie_name, cookie_value, cookie_exseconds);
  }
}