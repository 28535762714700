import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from '@components/core/header/header.component';
import { HomeComponent } from '@components/core/home/home.component';
import { SharedModule } from '@components/shared/shared.module';
import { SingleTestComponent } from '@components/core/single-test/single-test.component';
import { LoopTestComponent } from '@components/core/loop-test/loop-test.component';
import { MapComponent } from '@components/core/map/map.component';
import { StatisticsComponent } from '@components/core/statistics/statistics.component';
import { OpenDataComponent } from '@components/core/open-data/open-data.component';
import { HistoryComponent } from '@components/core/history/history.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from "@angular/material/dialog";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { ChartsModule } from 'ng2-charts';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { CountdownModule } from 'ngx-countdown';

import { QRCodeModule } from 'angularx-qrcode';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { HistoryDetailComponent } from './components/core/history-detail/history-detail.component';
import { PopupComponent } from './components/core/popup/popup.component';
import { YnDialogComponent } from './components/core/yn-dialog/yn-dialog.component';
import { InformationComponent } from './components/core/information/information.component';
import { FooterComponent } from './components/core/footer/footer.component';
import { PrivacyPolicyComponent } from './components/core/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './components/core/terms-of-use/terms-of-use.component';
import { PrivacyPolicyDialogComponent } from './components/core/privacy-policy-dialog/privacy-policy-dialog.component';
import { FaqComponent } from './components/core/faq/faq.component';
import { InfoPageComponent } from './components/core/info-page/info-page.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginationIntlService } from '@services/mat-pagination-intl.service';
import { DateNotBeforeDirective } from './util/date-not-before.directive';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HistoryComponent,
    HistoryDetailComponent,
    HomeComponent,
    InformationComponent,
    LoopTestComponent,
    MapComponent,
    OpenDataComponent,
    SingleTestComponent,
    StatisticsComponent,
    YnDialogComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    PrivacyPolicyDialogComponent,
    PopupComponent,
    FaqComponent,
    InfoPageComponent,
    DateNotBeforeDirective
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ChartsModule,
    CountdownModule,
    FormsModule,
    HttpClientModule,
    LeafletModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatExpansionModule,
    ReactiveFormsModule,
    SharedModule,
    QRCodeModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        },
        defaultLanguage: 'bg'
    })
  ],
  providers: [
    Title,
    { provide: DatePipe },
    { provide: MatPaginatorIntl, useClass: MatPaginationIntlService },
    { provide: MAT_DATE_LOCALE, useValue: 'bg-BG' }
  ],
  entryComponents: [
    PopupComponent
 ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}