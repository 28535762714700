<div>
    <mat-expansion-panel expanded="true" style="width:100%">
        <mat-expansion-panel-header>
            <mat-panel-title class="filter-title" translate>
                filter.map
                <mat-icon class="hint" [matTooltip]="selectedInfoLabel.text" *ngIf="selectedInfoLabel">info</mat-icon>
            </mat-panel-title>
        </mat-expansion-panel-header>
        
        <form #filterForm="ngForm" appDateNotBefore>
            <div style="display: inline-block;">
                <mat-form-field class="filter-field">
                    <mat-label translate>filter.periodFrom</mat-label>
                    <input matInput [matDatepicker]="pickerFrom" id="periodFrom" name="periodFrom" [(ngModel)]="filter.periodFrom">
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
    
                <mat-form-field class="filter-field">
                    <mat-label translate>filter.periodTo</mat-label>
                    <input matInput [matDatepicker]="pickerTo" id="periodTo" name="periodTo" [(ngModel)]="filter.periodTo">
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
    
                <mat-form-field class="filter-field">
                    <mat-label translate>filter.networkType</mat-label>
                    <mat-select id="networkType" name="networkType"
                        [(ngModel)]="filter.networkType"
                        [compareWith]="networkComparison"
                        name="networkType">
                        <mat-option *ngFor="let networkType of networkTypes" [value]="networkType">
                            {{networkType}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field class="filter-field">
                    <mat-label translate>filter.provider</mat-label>
                    <mat-select id="providerId" name="providerId"
                        [(ngModel)]="filter.providerId"
                        [compareWith]="idComparison"
                        name="providers">
                        <mat-option *ngFor="let provider of providers" [value]="provider.id">
                            {{provider.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field class="filter-field">
                    <mat-label translate>filter.region</mat-label>
                    <mat-select id="regionCode" name="regionCode" multiple
                        [(ngModel)]="filter.regionCodes"
                        [compareWith]="regionComparison"
                        name="regions">
                        <mat-option *ngFor="let region of regions" [value]="region.code">
                            {{getRegionFilterName(region)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    
        <mat-action-row class="filter-row">
            <div></div>
            <div>
                <button mat-stroked-button color="primary" (click)="clear()"><label translate>filter.clear</label></button>
                <button mat-raised-button color="primary" [disabled]="!filterForm.form.valid" (click)="search()"><label translate>filter.search</label></button>
            </div>
        </mat-action-row>
    </mat-expansion-panel>
    <div class="map-container">
        <div class="map-frame">
            <div id="map"
            leaflet
            (leafletMapReady)="onMapReady($event)"
            [leafletOptions]="mapOptions"></div>
        </div>
    </div>
</div>