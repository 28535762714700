import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  childRoute: any;
  embedded: boolean = false;

  public constructor(private titleService: Title,
    private router: Router,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
    ) { 
      this.egov();
      //detect if lang is changed
      translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.setTitle();
      });
      //detect router events
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe(() => {
        this.setTitle();
      });
    }

  ngOnInit() {}

  setTitle() {
    this.childRoute = this.getChild(this.activatedRoute);
    this.childRoute.data.subscribe(data => {
      this.translate.get(data.title).subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
    });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    }
    else {
      return activatedRoute;
    }
  }

  public egov() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['loop'] && "egov" === params['loop']) {
        this.embedded = true;
      }
   });
  }
}
