import { QOSPageMeasurement } from './qos-page-measurement';

export class QOSPageDetail {
    type: string;
    success_count: number;
    failure_count: number;

    items: QOSPageMeasurement[];

    constructor(type: string) {
        this.type = type;
        this.success_count = 0;
        this.failure_count = 0;
        this.items = [];
    }

    getRate(): string {
        return this.success_count + '/' + this.failure_count;
    }

    getItems(): string {
        var result = '';
        this.items.forEach(function(element) {
            result += element.success ? 'success' : 'fail';
            result += ': ' + element.description;
        });
        return result;
    }

    containsItems(): boolean {
        return this.items.length > 0;
    }
}