export class Coordinates {
    lat: number;
    lon: number;
    z: number;
    size: number = 20;

    constructor(lat: number, lon: number, zoom: number) {
        this.lat = lat;
        this.lon = lon;
        this.z = zoom;
    }
}