<div class="toolbar">
    <div class="nav-container-content main-container">
        <div class="lang">
            <!-- <ul >
                <li > -->
                    <button mat-button [matMenuTriggerFor]="languages">
                        <!-- <mat-icon>language</mat-icon> -->
                        <img class="langImg" src="{{selectedLang === 'EN' ? './assets/img/ENG.svg' : './assets/img/BUL.svg'}}" width="30pt" height="30pt" />
                    </button>
                    <mat-menu #languages="matMenu">
                        <button mat-menu-item (click)="changeLang('bg')">
                            <img class="langImg" src="./assets/img/BUL.svg" width="30pt" height="30pt" />
                            BG
                        </button>
                        <button mat-menu-item (click)="changeLang('en')">
                            <img class="langImg" src="./assets/img/ENG.svg" width="30pt" height="30pt" />
                            EN
                        </button>
                    </mat-menu>
                <!-- </li>
            </ul> -->
        </div>
        <!-- <div class="title">
                <h2 style="display: inline;" translate>header.title</h2>
        </div> -->
    </div>
</div>
<div class="nav-container">
    <div class="nav-container-content main-container">
        <div class="logo-containter" >
            <img alt="Logo" src="./assets/img/speedtest_logo.png" class="logo" routerLink="/home">
        </div>
        <div class="subtitle-containter">
            <h3 style="display: inline;" translate>header.subtitle</h3>
        </div>
        <div class="nav-content">
            <ul class="nav">
                <li routerLink="/statistics" translate>header.statistics</li>
                <li routerLink="/history" translate>header.history</li>
                <li routerLink="/map" translate>header.mapView</li>
                <li routerLink="/info-page" translate>home.Information</li>
                <!-- <li routerLink="/statistics" translate>header.statistics</li>   -->
                <!-- <li class="start-test-btn" routerLink="/test" translate>header.startTest</li> -->
            </ul>
        </div>
    </div>
</div>
<div>
    <div class="nav-container-content main-container">
        <ul class="home-nav">
            <li><a routerLink="/home" translate>header.home</a><label>{{prefix}}</label></li>
        </ul>
    </div>
</div>

 