import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { config } from '@env/config';
import { FileExport } from '@models/file-export';
import { Measurement } from '@models/measurement';
import { NetworkType } from '@models/network-type';
import { ParamVisibility } from '@models/param-visibility';
import { QOSDetail } from '@models/qos/qos-detail';
import { DssToken } from '@models/dss-token';


@Injectable({
    providedIn: 'root'
  })
  export class MeasurementService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };

      private emailSource = new BehaviorSubject<string>(null);
      email = this.emailSource.asObservable()

    constructor(private http: HttpClient) { }

    findAllNetworkTypes(group: string): Observable<string[]> {
      let requestParams = new HttpParams();
      if (group != undefined) {
        requestParams = requestParams.set("group", group);
      }
      
      return this.http.get<string[]>(config.BASE_WS_URL + config.public + config.network_type_groups, {params: requestParams})
          .pipe(
              timeout(config.timeout)
          );
    }

    public getTest(id: string): Observable<Measurement> {
      const url = `${config.BASE_WS_URL + config.public + config.measurements}/${id}`;
      return this.http.get<Measurement>(url).pipe(
        timeout(config.timeout)
      );
    }

    findQOS(id: string): Observable<QOSDetail> {
      const url = config.baseAddress + config.controlServerPath + config.qos + id + '/en';
      return this.http.get<QOSDetail>(url).pipe(
        timeout(config.timeout)
      );
    }

    generateTimestamp(uuid: string): Observable<DssToken> {
      let requestParams = new HttpParams();
      requestParams = requestParams.set("uuid", uuid);

      return this.http.get<DssToken>(config.BASE_WS_URL + config.public + config.timestamp, {params: requestParams}).pipe(timeout(config.timeout));
    }

    getVisibleQosParams(): Observable<ParamVisibility[]> {

      return this.http.get<ParamVisibility[]>(config.BASE_WS_URL + config.public + config.paramVisibility).pipe(timeout(config.timeout));
  	}

    sendMail(transfer: FileExport): Observable<any> {
      return this.http.post(config.BASE_WS_URL + config.public + '/export-mail', transfer, this.httpOptions).pipe(
        timeout(config.timeout)
      );
    }

    changeEmail(email: string) {
      this.emailSource.next(email);
  	}
  }