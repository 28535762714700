import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.css']
})
export class InfoPageComponent implements OnInit {

  constructor(private translate: TranslateService,private http: HttpClient) { }

  ngOnInit(): void {
  }

  public downloadGuide() {
    this.http.get('./assets/docs/User_guide_QoS_mechanism.pdf', { responseType: 'blob', observe: 'response' })
      .subscribe(respone => {
        let fileName = this.translate.instant('home.guide') + '.pdf';

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(respone.body);
        link.download = fileName;
        link.click();
      });
  }

}
