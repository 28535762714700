<div class="scrollable-popup">
    <table>
        <tr class="mat-row" *ngFor="let marker of markers">
            <div>
                <table class="popup-info">
                    <tr>
                        <td>
                          {{getTranslation('details.testTime')}}
                        </td>
                        <td>
                            {{marker.time_string}}
                        </td>
                    </tr>
                    <tr *ngIf="marker.measurement && marker.measurement[0]">
                        <td>
                            {{getTranslation('details.' + marker.measurement[0].title)}}
                        </td>
                        <td>
                            {{marker.measurement[0].value}}
                        </td>
                    </tr>
                    <tr *ngIf="marker.measurement && marker.measurement[1]">
                        <td>
                            {{getTranslation('details.' + marker.measurement[1].title)}}
                        </td>
                        <td>
                            {{marker.measurement[1].value}}
                        </td>
                    </tr>
                    <tr *ngIf="marker.measurement && marker.measurement[2]">
                        <td>
                            {{getTranslation('details.' + marker.measurement[2].title)}}
                        </td>
                        <td>
                            {{marker.measurement[2].value}}
                        </td>
                    </tr>
                    <tr *ngIf="marker.measurement && marker.measurement[3]">
                        <td>
                            {{getTranslation('details.' + marker.measurement[3].title)}}
                        </td>
                        <td>
                            {{marker.measurement[3].value}}
                        </td>
                    </tr>
                    <tr *ngIf="marker.network_info">
                        <td>
                            {{getTranslation('details.networkType')}}
                        </td>
                        <td>
                           {{marker.network_info.network_type_label}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{getTranslation('details.model')}}
                        </td>
                        <td>
                           {{marker.device}}
                        </td>
                    </tr>
                    <tr *ngIf="marker.network_info">
                        <td>
                            {{getTranslation('details.provider')}}
                        </td>
                        <td>
                           {{marker.network_info.provider_name}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                           {{getTranslation('details.region')}}
                        </td>
                        <td>
                           {{marker.region}}
                        </td>
                    </tr>
            
                    <tr>
                        <td colspan="2" align="right" style="background-color:white">
                            <button class="info-btn" (click)="goToDetails(marker.uuid)">{{getTranslation('details.showDetails')}}</button>
                        </td>
                    </tr>
                  </table>
            </div>
        </tr>
    </table>
</div>