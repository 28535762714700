import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgForm } from "@angular/forms";

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

import { TranslateService } from '@ngx-translate/core';

import { CountdownComponent } from 'ngx-countdown/countdown.component';
import { CountdownConfig } from 'ngx-countdown';

import { FileType } from '@app/enum/file-type.enum';
import { AbstractTestComponent } from '@components/abstract/abstract-test-component';
import { config, urls } from '@env/config';
import { LoopMode } from '@models/loop-mode';
import { Measurement } from '@models/measurement';
import { MeasurementCycle } from '@models/measurement-cycle';
import { TableTemplate } from '@models/table-template';
import { ExportService } from '@services/export.service';
import { LoopTestService } from '@services/loop-test.service';
import { MeasurementService } from '@services/measurement.service';
import { SettingsService } from '@services/settings.service';
import { YnDialogComponent } from '../yn-dialog/yn-dialog.component';
import { LoopMeasurement } from '@models/loop-measurement';
import { DssToken } from '@models/dss-token';
import { ExportParams } from '@models/export-params';
import { ActivatedRoute, Router } from '@angular/router';
import { InformationService } from '@services/information.service';
import { DomSanitizer } from '@angular/platform-browser';

declare const TestEnvironment: any;
declare const RMBTTestConfig: any;
declare const DemoTestVisualization: any;
declare const RMBTControlServerCommunication: any;
declare const RMBTTest: any;

const ONE_MIN = 60;
const STARTED_STATUS = "STARTED";
const FINISHED_STATUS = "FINISHED";
const ERROR_STATUS = "ERROR";
const ABORTED_STATUS = "ABORTED";

@Component({
  selector: 'app-loop-test',
  templateUrl: './loop-test.component.html',
  styleUrls: ['./loop-test.component.css']
})
export class LoopTestComponent extends AbstractTestComponent implements OnInit {

  @ViewChild('cd', { static: false }) cd: CountdownComponent;
  @ViewChild('form') form: NgForm;
  measurementCycles: MeasurementCycle[];
  selectedCycle?: MeasurementCycle;
  newLoopObj: LoopMode;
  newLoopMeasurementObj: LoopMeasurement;

  countdownConfig: CountdownConfig = { demand: true };
  loopStarted: boolean;
  loopFinished: boolean;
  lastTestStarted: boolean;
  errorStatus: boolean = false;
  abortedStatus: boolean = false;
  disconnection: boolean = false;
  width: number;
  currentTest: number;
  currentTestUUID: string;
  loopUUID: string;
  exportLoopUUID: string;
  lastTests: Measurement[] = [];
  dataSource = new MatTableDataSource<Measurement>();
  columns: TableTemplate[] = [
    { columnDef: 'orderNum', header: 'details.orderNum'},
    { columnDef: 'status', header: 'details.status'},
    { columnDef: 'time', header: 'details.testTime'},
    { columnDef: 'speedDownload',   header: 'loopTest.downSpeed'},
    { columnDef: 'speedUpload',   header: 'loopTest.upSpeed'},
    { columnDef: 'ping',   header: 'loopTest.pingInfo'},
    { columnDef: 'packetLoss',   header: 'details.packetLoss'},
  ];
  displayedColumns: string[];
  testStarted = false;
  countdownStarted = false;
  countdownInProgress = false;
  hasEmail = false;
  email: string;
  dssToken: DssToken = null;
  hasResult = false;
  countdownCounter = 0;
  urlStatisticsServer: string;
  mobile: boolean;
  android: boolean;
  ios: boolean;
  loopInterval: any;
  countdownInterval: any;
  certified: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loopTestService: LoopTestService,
    private measurementService: MeasurementService,
    private exportService: ExportService,
    private datepipe: DatePipe,
    snackBar: MatSnackBar,
    translate: TranslateService,
    dialog: MatDialog,
    settingsService: SettingsService,
    protected _changeDetectorRef: ChangeDetectorRef, 
    protected informationService: InformationService,
    protected sanitizer: DomSanitizer
    ) {
      super(snackBar, settingsService, dialog, translate, _changeDetectorRef, informationService, sanitizer, config.hintLoopCode)
   }

  ngOnInit(): void {
    this.mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (this.mobile) {
      this.android = /Android/i.test(navigator.userAgent);
      this.ios = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  
      if (this.android) {
        this.router.navigate(['home']);
        document.location.href = urls.googlePlay;
      }
  
      if (this.ios) {
        this.router.navigate(['home']);
        document.location.href = urls.appStore;
      }
    } else {
      this.settingsService.getSettings("", false).subscribe(res => {
        if (res.settings && res.settings[0] && res.settings[0].urls.url_statistic_server) {
          this.urlStatisticsServer = res.settings[0].urls.url_statistic_server;
        }
      })
      // this.loopTestService.getLoopUuid().subscribe(loopUUID => {
        //   if (loopUUID) {
        //     this.loopUUID = loopUUID;
        //     this.exportLoopUUID = "O" + this.loopUUID;
        //   }
        // })
      this.route.queryParams.subscribe(params => {
        this.certified = params['certified'];
        console.log(this.certified);
        if (this.certified == null) {
          this.certified = 'true';
        }
        console.log(this.certified);
        this.loopTestService.getMeasurementCycles(this.certified).subscribe(res => {
          this.measurementCycles = res;
        });
      });
      this.displayedColumns = this.columns.map(c => c.columnDef);
      this.currentTest = 0;
    }
    
  }

  ngOnDestroy() {
    clearInterval(this.loopInterval);
    clearInterval(this.countdownInterval);
  }

  sendEmail() {
    this.hasEmail = true;
  }

  onLoopMode() {
    if (this.clientUUID) {
      const dialogRef = this.dialog.open(YnDialogComponent, {
        data: {dialogTitle: this.translate.instant("messages.pleaseConfirm"), dialogText: this.translate.instant("messages.confirmLocationAndIP")}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.countdownConfig = { leftTime: this.selectedCycle.timeBetween * ONE_MIN, demand: true , notify: [ 5, 10, 20, 30, 40, 50, 60 ]};
          this.loopStarted = true;
          // this.loopTestService.addLoopMeasurement(this.createLoopMeasurementObj()).subscribe(newObj => {
          //   this.newLoopMeasurementObj = newObj;
          //   if (this.newLoopMeasurementObj) {
              this.loopInterval = setInterval(() => {
                if (navigator.onLine) {
                  if (!this.hasResult) {
                    if (!this.loopFinished) {
                      if(!this.testStarted && !this.countdownStarted) {
                        if (this.currentTest < this.selectedCycle.loopCount) {
                          this.currentTest++;
                          this.testStarted = true;
                          if (this.currentTest === this.selectedCycle.loopCount) {
                            this.lastTestStarted = true;
                          }
                          let sec = 1;
                          if (this.disconnection) {
                            sec = 2000;
                            this.disconnection = false;
                          }
                          setTimeout(() => {
                            this.startNewTest();
                          }, sec);
                        }
                      } else if (this.countdownStarted && !this.countdownInProgress) {
                        this.countdownInProgress = true;
                        this.startCountdown();
                      }
                    } else {
                      // if (this.newLoopMeasurementObj !== null) {
                      //   this.setLoopMeasurementStatus();
                      //   this.loopTestService.updateLoopMeasurement(this.newLoopMeasurementObj).subscribe(() => {
                          this.sendMail();
                          clearInterval(this.loopInterval);
                      //   });
                      // }
                    }
                  } else {
                    this.hasResult = false;
                    setTimeout(() => {
                      this.getResult()
                    }, 1000);
                  }
                } else {
                  this.disconnection = true;
                }
              }, 1500);
            // }
          // });
  
        } else {
          this.openSnackBar(this.translate.instant("messages.measurementDenied"), this.translate.instant("close"));
        }
      });
    }
  }

  startNewTest() {
    TestEnvironment.init(new DemoTestVisualization((success) =>{
      this.currentTestUUID =success["testUUID"];
      this.loopUUID = success["loopUUID"];
      if (this.loopUUID) {
        this.exportLoopUUID = "O" + this.loopUUID.substring(1);
      }
      // this.getResult();
      this.hasResult = true;
    }, (error) =>{
      this.currentTestUUID =error["testUUID"];
      this.loopUUID = error["loopUUID"];
      if (this.loopUUID) {
        this.exportLoopUUID = "O" + this.loopUUID.substring(1);
      }
      // this.getResult();
      this.errorStatus = true;
      this.hasResult = true;
    }
    ), null);
    var testConfig = new RMBTTestConfig("en", config.baseAddress, "RMBTControlServer");
    var ctrl = new RMBTControlServerCommunication(testConfig);
    testConfig.uuid = this.clientUUID;
    testConfig.email = this.email;
    var loopObj = null;
    if (this.loopUUID) {
      loopObj = {​"max_delay":this.selectedCycle.timeBetween,"test_counter":this.currentTest,"max_tests":this.selectedCycle.loopCount.toString(),"loop_uuid":this.loopUUID};
    } else {
      loopObj = {​"max_delay":this.selectedCycle.timeBetween,"test_counter":this.currentTest,"max_tests":this.selectedCycle.loopCount.toString()};
    }
    testConfig.additionalRegistrationParameters["loopmode_info"] = loopObj;
    var websocketTest = new RMBTTest(testConfig, ctrl);

    TestEnvironment.getTestVisualization().setRMBTTest(websocketTest);
    TestEnvironment.getTestVisualization().startTest();

    websocketTest.startTest();
  }

  getResult() {
    if (this.currentTestUUID) {
      this.measurementService.getTest(this.currentTestUUID).subscribe(res => {
        if (res) {
          // this.loopTestService.addLoopMode(this.createLoopModeObj(res)).subscribe(() => {
          //   this.newLoopObj = null;
          // });
          if (res.status && res.status === STARTED_STATUS) {
            this.abortedStatus = true;
          }
          this.lastTests.push(res)
          this.lastTests[this.lastTests.length - 1].orderNum = this.currentTest;
          this.dataSource.data = this.lastTests;
          this.currentTestUUID = undefined;
          this.testStarted = false;
          if (!this.lastTestStarted) {
            this.countdownStarted = true;
          } else {
            // if (this.loopUUID) {
            //   this.loopUUID = this.loopUUID.substring(1);
            //   this.measurementService.generateTimestamp(this.loopUUID).subscribe(res => {
            //     if (res) {
            //       this.dssToken = res;
            //     }
            //   });
            // }
            this.loopFinished = true;
          }
        }
      });
    }
  }

  // createLoopModeObj(obj: Measurement) {
  //   this.newLoopObj = new LoopMode();
  //   this.newLoopObj.loopModeLoopUid=this.loopUUID;
  //   this.newLoopObj.loopModeUid=0;
  //   this.newLoopObj.testUid = obj.testUuid;
  //   this.newLoopObj.testCounter = this.currentTest;

  //   return this.newLoopObj;
  // }

  // createLoopMeasurementObj() {
  //   this.newLoopMeasurementObj = new LoopMeasurement();
  //   this.newLoopMeasurementObj.loopModeLoopUid=this.loopUUID;
  //   this.newLoopMeasurementObj.loopModeUid=0;
  //   this.newLoopMeasurementObj.clientUid = this.clientUUID;
  //   this.newLoopMeasurementObj.maxMovement= 0;
  //   this.newLoopMeasurementObj.maxDelay = this.selectedCycle.timeBetween;
  //   this.newLoopMeasurementObj.maxTests = this.selectedCycle.loopCount;
  //   this.newLoopMeasurementObj.email = this.email;

  //   return this.newLoopMeasurementObj;
  // }

  setLoopMeasurementStatus() {
    // if (!this.errorStatus && !this.abortedStatus) {
      this.newLoopMeasurementObj.status = FINISHED_STATUS;
    // } else {
    //   if (this.errorStatus) {
    //     this.newLoopMeasurementObj.status = ERROR_STATUS;
    //   } else {
    //     this.newLoopMeasurementObj.status = ABORTED_STATUS;
    //   }
    // }

    // if (this.dssToken !== null) {
    //   this.newLoopMeasurementObj.generatedTimestamp = this.dssToken.dssTimestamp;
    //   this.newLoopMeasurementObj.dssToken = this.dssToken.dssSerialNumber.toString();
    // }
  }

  onNotifyEvent(e:Event){
    if (e["action"] === "notify" && e["left"]){
      this.countdownCounter = e["left"]/1000;
     }
   }

  startCountdown(): void {
    this.width=100;
        let totalInterval = this.selectedCycle.timeBetween * ONE_MIN; 
        this.cd.begin();
        this.countdownCounter = totalInterval;
        this.countdownInterval = setInterval(() => {
          this.countdownCounter-=1;
          this.width = this.countdownCounter/totalInterval*100;
          if (this.width < 1) {
            this.countdownStarted = false;
            this.countdownInProgress = false;
            clearInterval(this.countdownInterval);
          }
        },1000)
  }
  
  canChangeVisibility(val: string): boolean {
    if(val === 'speedDownload' || val === 'speedUpload' || val === 'ping' || val === 'packetLoss') {
      return true;
    }
    return false;
  }

  getValue(val: number): string {
    return val === undefined ? this.translate.instant("loopTest.notUsed") : val+'';
  }
  
  getAverageDownload() {
    if (this.lastTests.length < 1) {
      return '-';
    }
    let count = this.lastTests.filter(t => t.speedDownload).length;

    return this.roundValue(this.lastTests.filter(t => t.speedDownload).map(t => t.speedDownload).reduce((acc, value) => (acc + value), 0)/count);
  }

  getAverageUpload() {
    if (this.lastTests.length < 1) {
      return '-';
    }
    let count = this.lastTests.filter(t => t.speedUpload).length;
    
    return this.roundValue(this.lastTests.filter(t => t.speedUpload).map(t => t.speedUpload).reduce((acc, value) => (acc + value), 0)/count);
  }

  getAveragePing() {
    if (this.lastTests.length < 1) {
      return '-';
    }
    let count = this.lastTests.filter(t => t.ping).length;

    return this.roundValue(this.lastTests.filter(t => t.ping).map(t => t.ping).reduce((acc, value) => (acc + value), 0)/count);
  }

  getAveragePacketLoss() {
    if (this.lastTests.length < 1) {
      return '-';
    }
    let count = this.lastTests.filter(t => t.packetLoss).length;

    return this.roundValue(this.lastTests.filter(t => t.packetLoss).map(t => t.packetLoss).reduce((acc, value) => (acc + value), 0)/count);
  }

  roundValue(value: number): number {
    if (value) {
      return Math.round((value + Number.EPSILON) * 100) / 100
    }
    return 0;
  }

  //Export
  exportToPdf(): void {
    if (this.loopUUID && this.urlStatisticsServer) {
      let exportParams = new ExportParams();
      exportParams.lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
      exportParams.loopUuid = this.exportLoopUUID;
      exportParams.sendMail = false;
      this.exportService.exportFile(exportParams, this.urlStatisticsServer, FileType.PDF,
         this.translate.instant("reports.measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), false);
    }


    // this.exportService.exportMeasurementAsPdfFile(this.prepareDataToExport(), this.getHeading(),
    //         this.translate.instant("measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), this.getHeader());
  }

  exportToExcel(): void {
    if (this.loopUUID && this.urlStatisticsServer) {
      let exportParams = new ExportParams();
      exportParams.lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
      exportParams.loopUuid = this.exportLoopUUID;
      exportParams.sendMail = false;
      this.exportService.exportFile(exportParams, this.urlStatisticsServer, FileType.XLXS,
         this.translate.instant("reports.measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), false);
    }

    // this.exportService.exportMeasurementAsExcelFile(this.prepareDataToExport(), this.getHeading(),
    //         this.translate.instant("reports.measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), [[this.getHeader()]]);
  }

  exportToCsv(): void {
    if (this.loopUUID && this.urlStatisticsServer) {
      let exportParams = new ExportParams();
      exportParams.lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
      exportParams.loopUuid = this.exportLoopUUID;
      exportParams.sendMail = false;
      this.exportService.exportFile(exportParams, this.urlStatisticsServer, FileType.CSV,
         this.translate.instant("reports.measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), false);
    }


    // this.exportService.exportMeasurementAsCsvFile(this.prepareDataToExport(), this.getHeading(),
    //         this.translate.instant("reports.measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), [[this.getHeader()]]);
  }

  // getHeader(): string {
  //   return this.translate.instant("reports.measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! });
  // }

  // getHeading() : string[][] {
  //   return [[this.translate.instant("param"), 
  //             this.translate.instant("value")]]
  // }

  // prepareDataToExport(): Array<any[]> {
  //   let body: Array<any[]> = [];
  //   this.lastTests.forEach(measurement => {
  //     body.push(this.exportService.prepareDataToExport(measurement, this.translate));
  //   })
  //   return body;
  // }

  // getMeasurementParamsValues(): any[] {
  //   let paramsArray: any[] = [];

  //   let filter = [];
  //   this.lastTests.forEach((obj, index, array) => {
  //     filter = [
  //       index + 1, 
  //       this.translate.instant("measurements.test_status." + obj.status),
  //       obj.time,
  //       this.getValue(obj.speedDownload),
  //       this.getValue(obj.speedUpload),
  //       this.getValue(obj.ping),
  //       this.getValue(obj.packetLoss)
  //     ];

  //     paramsArray.push(filter);
  //   })
    
  //   return paramsArray;
  // }

  // getMeasurementParamsValuesHeading() : string[][] {
    
  //   return [[this.translate.instant("details.orderNum"), 
  //          this.translate.instant("details.status"),
  //          this.translate.instant("details.testTime"),
  //          this.translate.instant("history.downSpeed"),
  //          this.translate.instant("history.upSpeed"),
  //          this.translate.instant("history.ping"),
  //          this.translate.instant("details.packetLoss")]]
  // }

  // getMeasurements(): any[] {
  //   let paramsArray: any[] = [];

  //   let filter = [];
  //   this.lastTests.forEach((obj, index, array) => {
  //     filter = [
  //       index + 1, 
  //       obj.time,
  //       obj.testUuid,
  //       this.translate.instant("measurements.test_status." + obj.status)
  //     ];

  //     paramsArray.push(filter);
  //   })
    
  //   return paramsArray;
  // }

  // getMeasurementsHeading() : string[][] {
    
  //   return [[this.translate.instant("details.orderNum"), 
  //          this.translate.instant("details.testTime"),
  //          this.translate.instant("details.testId"),
  //          this.translate.instant("details.status")]]
  // }

  // getMeasurementParams(): any[] {
  //   let paramsArray: any[] = [];

  //   let filter = [
  //     this.translate.instant("details.measurementType"), 
  //     this.getCycleDetails()
      
  //   ];
  //   paramsArray.push(filter);
    
  //   filter = [
  //     this.translate.instant("details.measurementCount"), 
  //     this.selectedCycle.loopCount + "/" + this.selectedCycle.loopCount
  //   ];
  //   paramsArray.push(filter);

  //   filter = [
  //     this.translate.instant("details.status"), 
  //     this.translate.instant("measurements.loop_status." + this.newLoopMeasurementObj.status)
  //   ];
  //   paramsArray.push(filter);

  //   return paramsArray;
  // }

  // getCycleDetails(): string {
  //   if (this.selectedCycle) {
  //     return this.translate.instant("loop.configurationLabel", {testsNumber: this.selectedCycle.loopCount, timeBetween: this.selectedCycle.timeBetween})
  //   } else {
  //     return "";
  //   }
  // }

  // Mailing
  private sendMail() {
    if (this.loopUUID && this.urlStatisticsServer) {
      let exportParams = new ExportParams();
      exportParams.lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
      exportParams.loopUuid = this.exportLoopUUID;
      exportParams.sendMail = true;
      exportParams.recipient = this.email;
      this.exportService.sendMail(exportParams, this.urlStatisticsServer, FileType.PDF, this.translate.instant("measurementDetails"));
    }

    // let fileData = this.exportService.buildCertifiedMeasurementPdfFile(this.prepareDataToExport(), this.getHeading(),
    // this.translate.instant("measurementDetails", { date: this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')! }), this.getHeader(),
    // this.getMeasurementParams(), this.translate.instant("details.executedMeasurement"), this.getMeasurements(), this.getMeasurementsHeading(),
    // this.translate.instant("details.measurementParamsValues"), this.getMeasurementParamsValues(), this.getMeasurementParamsValuesHeading(),
    // this.translate.instant("details.measurementDetailsInfo"), this.dssToken !== null ? this.dssToken.dssTimestamp.toString() : '');
    
    // this.send(this.email!, fileData.output(), FileType.PDF);
  }

  // private send(email: string, fileData: string, mimeType: FileType) {
  //   this.measurementService.sendMail(new FileExport(email, btoa(fileData), mimeType, this.translate.currentLang ? this.translate.currentLang.toLocaleUpperCase() : 'BG'))
  //       .subscribe(details => {
  //         this.openSnackBar(this.translate.instant("messages.loopMailSent"), this.translate.instant("close"));
  //   });
  // }
}
