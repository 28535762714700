import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Test } from '@models/test';
import { TableTemplate } from '@models/table-template';
import { config } from '@env/config';

@Component({
  selector: 'app-open-data',
  templateUrl: './open-data.component.html',
  styleUrls: ['./open-data.component.css']
})
export class OpenDataComponent implements OnInit {

  url: string = "https://data.egov.bg/organisation/dataset/ea08a3cf-c113-4433-be29-b54abdc0fe4a";

  constructor() { }

  ngOnInit(): void {
  }

}
