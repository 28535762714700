import { Component, OnInit } from '@angular/core';
import { urls } from '@env/config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  googlePlay: string = urls.googlePlay;
  appStore: string = urls.appStore;
  visible:boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  redirectToAppSore() {
    document.location.href = this.appStore;
  }

  redirectToGooglePlay() {
    document.location.href = this.googlePlay;
  }

}
