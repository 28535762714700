<div class="card-container">
    <div class="card-row">
        <div class="card card-small" routerLink="/test">
            <app-shortcut-element [subTitle]="'home.startTest' | translate" [title]="'singleTest' | translate"></app-shortcut-element>
            <mat-icon>play_circle_outline</mat-icon>
        </div>

        <div class="card card-small" routerLink="/loop" [queryParams]="{certified: 'false'}">
            <app-shortcut-element [subTitle]="'home.loopModeInfo' | translate" [title]="'home.loopMode' | translate"></app-shortcut-element>
            <mat-icon class="small-icon">loop</mat-icon>
        </div>
    </div>
    <div class="card-row">
        <div class="card card-small" routerLink="/loop" [queryParams]="{certified: 'true'}">
            <app-shortcut-element [subTitle]="'home.certifiedLoopModeInfo' | translate" [title]="'home.certifiedLoopMode' | translate"></app-shortcut-element>
            <mat-icon class="small-icon">bookmark</mat-icon>
        </div>

        <div class="card card-small" routerLink="/map">
            <app-shortcut-element [subTitle]="'home.mapInfo' | translate" [title]="'home.map' | translate"></app-shortcut-element>
            <mat-icon class="small-icon">public</mat-icon>
        </div>
    </div>
    <div class="card-row">
        <!-- <div class="card card-small">
            <app-shortcut-element [subTitle]="'home.openDataInfo' | translate" [title]="'home.openData' | translate" routerLink="/search"></app-shortcut-element>
        </div> -->

        <div class="card card-small" routerLink="/statistics">
            <app-shortcut-element [subTitle]="'home.statisticsInfo' | translate" [title]="'header.statistics' | translate"></app-shortcut-element>
            <mat-icon class="small-icon">bar_chart</mat-icon>
        </div>
        <div class="card card-small" routerLink="/history">
            <app-shortcut-element [subTitle]="'home.historyInfo' | translate" [title]="'home.history' | translate"></app-shortcut-element>
            <mat-icon class="small-icon">history</mat-icon>
        </div>
    </div>
    <div class="card-row">

        <div class="card card-small">
            <app-shortcut-element [subTitle]="'home.InformationInfo' | translate" [title]="'home.Information' | translate" routerLink="/info-page"></app-shortcut-element>
            <mat-icon class="small-icon">info</mat-icon>
        </div>
        
        <div></div>

       <!--  <div class="card card-small card--providers" (click)="redirectToAdminPanel()">
            <app-shortcut-element [subTitle]="'home.newProviderInfo' | translate" [title]="'home.newProvider' | translate"></app-shortcut-element>
            <mat-icon class="small-icon">add_circle</mat-icon>
        </div> -->
    </div>
    <!-- <div class="card-row">
        <div class="card card-small">
            <app-shortcut-element [subTitle]="'home.InformationInfo' | translate" [title]="'home.Information' | translate" routerLink="/information"></app-shortcut-element>
        </div>
        <div></div>
    </div> -->
</div>
