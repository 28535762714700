import { Component, OnInit, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { MeasurementMarker } from '@models/measurement-marker';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  // @Input() test: Measurement;
  @Input() markers: MeasurementMarker[];
  @Input() testTime: string;
  @Input() selectedLang: string;

  constructor(private translate: TranslateService, private router: Router, private zone: NgZone) { }

  ngOnInit(): void {
  }

  public getTranslation(wordYouNeedToTranslate: string): string {
    return this.translate.instant(wordYouNeedToTranslate);
  }

  public goToDetails(uuid: string) {
    this.zone.run(() => {
      this.router.navigate(['history/detailed-result'], { queryParams:
        { id: uuid}
       });
    });
  }

  // getRegionName(): string {
  //   if (this.translate.currentLang) {
  //     this.selectedLang = this.translate.currentLang.toUpperCase();
  //   } else if (this.translate.defaultLang) {
  //     this.selectedLang = this.translate.defaultLang.toUpperCase();
  //   }

  //   if (this.selectedLang === 'BG') {
  //     return this.test.region;
  //   } else {
  //     return this.test.regionLat;
  //   }

  //   return '';
  // }
}
