<h1 translate>singleTest</h1>
<div *ngIf="!testStarted">
    <form #test="ngForm" class="loop-form">
        <div>
            <div>
                <label for="email" translate>test.optionalEmail</label>
                <div>
                    <input #emailInput="ngModel" type="text" name="email" id="email" ngModel email [(ngModel)]="email" pattern=".+@.+\.[a-z]+">
                    <mat-icon class="hint" [matTooltip]="selectedInfoLabel.text" *ngIf="selectedInfoLabel">info</mat-icon>
                    <div *ngIf="emailInput.touched && emailInput.errors?.email" class="validation-error" translate="">loopTest.invalidEmail</div>
                </div>
            </div>
            <div>
                <button class="loop-btn start-loop-btn" type="button" (click)="onStartTest()" [disabled]="(test.invalid && email) || !termsAccepted" translate>singleTest</button>
            </div>
         </div>
    </form>
</div>
<div style="padding-top: 2em;" *ngIf="testStarted">
    <div class="progress">
        <div id="testprogress" class="progress-bar" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style="min-width: 3em; width: 0%;">0 %</div>
    </div>
    <div id="infocurrent">
        <div class="next-row">
            <div class="first-column" translate>details.status</div>
            <div class="second-column" id="infostatus" translate>-</div>
        <div class="next-row">
            <div class="first-column" translate>loopTest.ping</div>
            <div class="second-column" id="infoping"><div class="loader" style="display: none;"></div> <span>-</span></div>
            <div class="first-column" translate>loopTest.download</div>
            <div class="second-column" id="infodown"><div class="loader" style="display: none;"></div> <span>-</span></div>
            <div class="first-column" translate>loopTest.upload</div>
            <div class="second-column" id="infoup"><div class="loader" style="display: none;"></div> <span>-</span></div>
        </div>
        <div class="next-row">
            <div class="first-column" translate>loopTest.coordinates</div>
            <div class="second-column" id="infogeo">-</div>
            <div class="first-column" translate>loopTest.ip</div>
            <div class="second-column" id="infoip">-</div>
            <!-- <div class="first-column" translate>loopTest.operator</div>
            <div class="second-column" id="infoprovider">-</div> -->
            <div class="first-column" translate>details.publicIPAsName</div>
            <div class="second-column" id="infoasname">-</div>
        </div>
    </div>
</div>


