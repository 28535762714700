import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appDateNotBefore]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateNotBeforeDirective, multi: true }]
})
export class DateNotBeforeDirective implements Validator {

  validate(control: AbstractControl) {
    const from = control.get('periodFrom');
    const to = control.get('periodTo');

    if (from !== null && from.value !== undefined && to !== null && to.value !== undefined) {
          return from.value > to.value ? { validRange: true } : null;
      } else {
          return null;
      }
  }
}
