import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { config } from '@env/config';
import { Region } from '@models/region';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  findAllRegions(): Observable<Region[]> {
    return this.http.get<Region[]>(config.BASE_WS_URL + config.public + config.regions)
        .pipe(
            timeout(config.timeout)
        );
  }
}