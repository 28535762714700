export class Filter {
    region_code: string;
    // period: string;
    provider: string;
    statistical_method: string = '0.5';
    technology: string;
    from: string;
    to: string;

    constructor(region_code: string, from: string, to: string, provider: string, technology: string) {
        this.region_code = region_code;
        this.provider = provider;
        this.technology = technology;
        this.from = from;
        this.to = to;
    }
}