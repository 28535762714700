import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import * as html2pdf from 'html2pdf.js'
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf'
import 'jspdf-autotable';

import { base64Fonts } from '@env/fonts';
import { Measurement } from '@models/measurement';
import { config, urls } from '@env/config';
import { timeout } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ExportParams } from '@models/export-params';
import { FileType } from '@app/enum/file-type.enum';
import { MeasurementFilter } from '@models/measurement-filter';
import { DatePipe } from '@angular/common';
 
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const PDF_TYPE = 'application/pdf;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const PDF_EXTENSION = '.pdf';
const CSV_EXTENSION = '.csv';

@Injectable({ providedIn: 'root' })
export class ExportService {
 
  protected httpOptions = {
    headers: new HttpHeaders({ 'accept': '*/*' }),
    responseType: 'blob' as 'json'
};
  constructor(
    private datepipe: DatePipe,
    private translate:TranslateService,
    private http: HttpClient
    ) { }

    public sendMail(exportParams: ExportParams, statisticServerPath: string, exportType: string, fileName: string) {
      this.exportFile(exportParams, statisticServerPath, exportType, fileName, true);
    }

  public exportFile(exportParams: ExportParams, statisticServerPath: string, exportType: string, fileName: string, sendMail: boolean): any {
    let url = statisticServerPath + config.export + exportType + "?" + this.toQueryString(exportParams);
    return this.http.get(url, this.httpOptions).subscribe(
      (response: any) =>{
        if (!sendMail) {
          if (exportType === FileType.PDF) {
            FileSaver.saveAs(response, fileName);
          } else if (exportType === FileType.XLXS) {
            this.saveAsExcelFile(response, fileName);
          } else if (exportType === FileType.CSV) {
            this.saveAsCsvFile(response, fileName);
          }
        }
      }
    );
  }

  private toQueryString(exportParams: ExportParams): string {
    var parts = [];

    if (exportParams.lang) {
      parts.push("lang" + '=' +  exportParams.lang);
    }

    if (exportParams.openTestUuid) {
      parts.push("open_test_uuid" + '=' +  exportParams.openTestUuid);
    } else if (exportParams.loopUuid) {
      parts.push("loop_uuid" + '=' +  exportParams.loopUuid);
    }

    if (exportParams.sendMail) {
      parts.push("send_mail" + '=' +  exportParams.sendMail.toString());
      parts.push("recipient" + '=' +  exportParams.recipient);
    }

    return parts.join('&');
 }

 exportHistory(filter: MeasurementFilter, format: string, lang: string, fileName: string): any {
  let url = urls.historyExport + "?" + this.exportParamsToQueryString(filter.periodFrom, filter.periodTo, filter.uuid, format, lang);

  return this.http.get<any>(url, this.httpOptions).subscribe(
        (response: any) => {
          this.saveFile(format, response, fileName);
      });
  }

  saveFile(format: string, response: Blob, fileName: string) {
    if (format === 'pdf') {
      // FileSaver.saveAs(response, fileName);
      this.saveAsPdfFile(response, fileName);
    } else if (format === 'xlsx') {
      this.saveAsExcelFile(response, fileName);
    } else if (format === 'csv') {
      this.saveAsCsvFile(response, fileName);
    }
  }

  private saveAsPdfFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: PDF_TYPE
    });
    FileSaver.saveAs(data, fileName + PDF_EXTENSION);
  }

  private exportParamsToQueryString(periodFrom: Date|undefined, periodTo: Date|undefined, clientUuid: string, format: string, lang: string): string {
    var parts : string[] = [];


    if (periodFrom != undefined) {
      parts.push
      parts.push("from" + '=' + this.datepipe.transform(periodFrom, 'yyyy-MM-dd')!);
    }
    
    if (periodTo != undefined) {
      parts.push("to" + '=' + this.datepipe.transform(periodTo, 'yyyy-MM-dd')!);
    }

    if (clientUuid != undefined) {
      parts.push("clientUuid" + '=' + clientUuid);
    }

    parts.push("format" + '=' + format);
    if (lang !== null && lang === 'EN') {
      parts.push("lang" + '=' + 'en');
    } else {
      parts.push("lang" + '=' + 'bg');
    }
    
      return parts.join('&');
  }

  // public exportAsCsvFile(body: any[], heading: string[][], fileName: string, header: any[], headingFilter: string[][], filters: any[], startCell: number): void {
  //   const myworksheet: XLSX.WorkSheet = XLSX.utils.book_new();
  //   XLSX.utils.sheet_add_json(myworksheet, header, { origin: 'A1', skipHeader: true });
    
  //   XLSX.utils.sheet_add_aoa(myworksheet, headingFilter, { origin: 'A3' });
  //   XLSX.utils.sheet_add_json(myworksheet, filters, { origin: 'A4', skipHeader: true });

  //   XLSX.utils.sheet_add_aoa(myworksheet, heading, { origin: startCell });
  //   XLSX.utils.sheet_add_json(myworksheet, body, { origin: startCell + 1, skipHeader: true });
  //   this.saveAsCsvFile(XLSX.utils.sheet_to_csv(myworksheet), fileName);
  // }

  // public exportAsExcelFile(body: any[], heading: string[][], fileName: string, header: any[], headingFilter: string[][], filters: any[], startCell: number): void {
  //   const myworksheet: XLSX.WorkSheet = XLSX.utils.book_new();
  //   XLSX.utils.sheet_add_json(myworksheet, header, { origin: 'A1', skipHeader: true });

  //   XLSX.utils.sheet_add_aoa(myworksheet, headingFilter, { origin: 'A3' });
  //   XLSX.utils.sheet_add_json(myworksheet, filters, { origin: 'A4', skipHeader: true });

  //   XLSX.utils.sheet_add_aoa(myworksheet, heading, { origin: startCell });
  //   XLSX.utils.sheet_add_json(myworksheet, body, { origin: startCell + 1, skipHeader: true });
  //   const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
  //   const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });

  //   this.saveAsExcelFile(excelBuffer, fileName);
  // }

  // public exportAsPdfFile(body: any[], heading: string[][], fileName: string, header: string, headingFilter: string[][], filters: string[]) {
  //   let pdf = new jsPDF();
  //   pdf.addFileToVFS('Ubuntu-R-normal.ttf', base64Fonts.ubuntuRegular);
  //   pdf.addFont('Ubuntu-R-normal.ttf', 'Ubuntu-R', 'normal');
  //   pdf.setFont('Ubuntu-R');
  //   pdf.setFontSize(18);

  //   var splitTitle = pdf.splitTextToSize(header, 180);
  //   pdf.text(splitTitle, 11, 8);

  //   pdf.setFontSize(12);
  //   pdf.setTextColor(99);

  //   (pdf as any).autoTable({
  //     head: headingFilter,
  //     body: filters,
  //     margin: { top: 30 },
  //     styles: { font: 'Ubuntu-R' },
  //     theme: 'striped',
  //   });

  //   (pdf as any).autoTable({
  //     head: heading,
  //     body: body,
  //     margin: { top: 10 },
  //     styles: { font: 'Ubuntu-R' },
  //     theme: 'striped',
  //   });

  //   pdf.save(fileName + PDF_EXTENSION)
  // }

  // Measurements
  // public exportMeasurementAsCsvFile(body: Array<any[]>, heading: string[][], fileName: string, header: any[]): void {
  //   const myworkbook: XLSX.WorkBook = {SheetNames:[], Sheets:{}};
  //   let list: any[] = [];
  //   body.forEach((val, index, array) => {
  //     if (index === 0) {
  //       list.push(XLSX.utils.sheet_to_csv(this.buildMeasurementWorkbook(val, heading, header)));
  //     } else {
  //       list.push(XLSX.utils.sheet_to_csv(this.buildMeasurementWorkbook(val, heading, [])));
  //     }
  //  });
  //   const excelBuffer: any = list;

  //   this.saveAsCsvFile(excelBuffer, fileName);
  // }

  // public exportMeasurementAsExcelFile(body: Array<any[]>, heading: string[][], fileName: string, header: any[]): void {
  //   const myworkbook: XLSX.WorkBook = {SheetNames:[], Sheets:{}};
  //   body.forEach((val, index, array) => {
  //     let sheetName = "Sheet-" + (index + 1);
  //     myworkbook.SheetNames.push(sheetName);
  //     myworkbook.Sheets[sheetName] = this.buildMeasurementWorkbook(val, heading, header);

  //  });
  //   const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });

  //   this.saveAsExcelFile(excelBuffer, fileName);
  // }

  // public workbookCSVToBase64(body: any[], heading: string[][], header: any[]): Blob {
  //   const excelBuffer: any = XLSX.utils.sheet_to_csv(this.buildMeasurementWorkbook(body, heading, header));

  //   const data: Blob = new Blob([excelBuffer], {
  //     type: EXCEL_TYPE
  //   });

  //   return data;
  // }

  // public workbookXLSXToBase64(body: any[], heading: string[][], header: any[]): Blob {
  //   const myworkbook: XLSX.WorkBook = { Sheets: { 'data': this.buildMeasurementWorkbook(body, heading, header) }, SheetNames: ['data'] };
  //   const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });

  //   const data: Blob = new Blob([excelBuffer], {
  //     type: EXCEL_TYPE
  //   });

  //   return data;
  // }

  // private buildMeasurementWorkbook(body: any[], heading: string[][], header: any[]): XLSX.WorkSheet {
  //   const myworksheet: XLSX.WorkSheet = XLSX.utils.book_new();
  //   XLSX.utils.sheet_add_json(myworksheet, header, { origin: 'A1', skipHeader: true });
  
  //   XLSX.utils.sheet_add_aoa(myworksheet, heading, { origin: 'A3' });
  //   XLSX.utils.sheet_add_json(myworksheet, body, { origin: 'A4', skipHeader: true });

  //   return myworksheet;
  // }

  // public exportMeasurementAsPdfFile(body: Array<any[]>, heading: string[][], fileName: string, header: string) {
  //   let pdf = this.buildMeasurementPdfFile(body, heading, fileName, header);
  //   pdf.save(fileName + PDF_EXTENSION)
  // }

  // public buildMeasurementPdfFile(body: Array<any[]>, heading: string[][], fileName: string, header: string): jsPDF {
  //   let pdf = new jsPDF();
  //   pdf.addFileToVFS('Ubuntu-R-normal.ttf', base64Fonts.ubuntuRegular);
  //   pdf.addFont('Ubuntu-R-normal.ttf', 'Ubuntu-R', 'normal');
  //   pdf.setFont('Ubuntu-R');
  //   pdf.setFontSize(18);

  //   var splitTitle = pdf.splitTextToSize(header, 180);
  //   pdf.text(splitTitle, 11, 8);

  //   pdf.setFontSize(12);
  //   pdf.setTextColor(99);

  //   body.forEach((val, index, array) => {
  //     (pdf as any).autoTable({
  //       head: heading,
  //       body: val,
  //       margin: { top: 10 },
  //       styles: { font: 'Ubuntu-R' },
  //       theme: 'striped',
  //     });
      
  //     if (index !== array.length - 1){ 
  //       pdf.addPage();
  //     }
  //  });

  //   return pdf;
  // }

  // public exportChartAsPdf(content: Element, fileName: string) {
  //   const options = {
  //       filename: fileName,
  //       image: {type: 'jpeg'},
  //       html2canvas: {},
  //       jsPDF: {
  //       orientation: 'landscape',
  //       format: [500, 500],
  //     }
  //   };

  //   html2pdf().from(content).set(options).save();
  // }

  private saveAsCsvFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + CSV_EXTENSION);
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  // prepareDataToExport(measurement: Measurement, translate: TranslateService): any[] {
  //   let lang = null;
  //   if (translate.currentLang) {
  //     lang = translate.currentLang.toUpperCase();
  //   } else {
  //     lang = translate.defaultLang.toUpperCase();
  //   }
  //   let exportArray: any[] = [];

  //   let filter = [
  //     translate.instant("details.testTime"), 
  //     measurement.time
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.status"),
  //     translate.instant('measurements.test_status.' + measurement.status)
  //   ];
  //   exportArray.push(filter);

  //   if (measurement.speedDownload) {
  //     filter = [
  //       translate.instant("details.downloadSpeed"), 
  //       measurement.speedDownload
  //     ];
  //     exportArray.push(filter);
  //   }

  //   if (measurement.speedUpload) {
  //     filter = [
  //       translate.instant("details.uploadSpeed"), 
  //       measurement.speedUpload
  //     ];
  //     exportArray.push(filter);
  //   }

  //   if (measurement.ping) {
  //     filter = [
  //       translate.instant("details.ping"), 
  //       measurement.ping
  //     ];
  //     exportArray.push(filter);
  //   }

  //   if (measurement.packetLoss) {
  //     filter = [
  //       translate.instant("details.packetLoss"), 
  //       measurement.packetLoss
  //     ];
  //     exportArray.push(filter);
  //   }

  //   if (measurement.signalStrength) {
  //     filter = [
  //       translate.instant("details.signal"), 
  //       measurement.signalStrength
  //     ];
  //     exportArray.push(filter);
  //   }

  //   if (measurement.blockedPorts) {
  //     filter = [
  //       translate.instant("details.blockedPorts"), 
  //       measurement.blockedPorts
  //     ];
  //     exportArray.push(filter);
  //   }

  //   filter = [
  //     translate.instant("details.measurementCode"),
  //     translate.instant(measurement.code) 
      
  //   ];
  //   exportArray.push(filter);

  //   if (measurement.loopMode !== undefined) {
  //     filter = [
  //       translate.instant("details.measurementPrefix"), 
  //       measurement.loopMode.loopModeLoopUid
  //     ];
  //     exportArray.push(filter);

  //     filter = [
  //       translate.instant("details.measurementId"), 
  //       measurement.loopMode.loopModeUid
  //     ];
  //     exportArray.push(filter);
  //   }

  //   filter = [
  //     translate.instant("details.ip"), 
  //     measurement.ip + '.*'
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.position"), 
  //     measurement.geolocation
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.city"), 
  //     lang !== null && lang === 'BG' ? measurement.city : measurement.cityLat
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.region"), 
  //     lang !== null && lang === 'BG' ? measurement.region : measurement.regionLat
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.provider"), 
  //     measurement.provider
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.networkType"), 
  //     measurement.networkType
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.softwareName"), 
  //     measurement.clientName
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.softwareVersion"), 
  //     measurement.clientSoftwareVersion
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.model"), 
  //     measurement.model
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.browserVersion"), 
  //     measurement.modelVersion
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.hostname"), 
  //     measurement.hostname
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.duration"), 
  //     measurement.testDuration
  //   ];
  //   exportArray.push(filter);
    
  //   filter = [
  //     translate.instant("details.testId"), 
  //     measurement.testUuid
  //   ];
  //   exportArray.push(filter);

  //   filter = [
  //     translate.instant("details.clientId"), 
  //     measurement.clientUuid
  //   ];
  //   exportArray.push(filter);

  //   return exportArray;
  // }

  // public buildCertifiedMeasurementPdfFile(body: Array<any[]>, heading: string[][], fileName: string, header: string, measurementParams: any[], 
  //   measurementsHeader: string, measurements: any[], measurementsHeading: string[][], measurementsParamsValuesHeader: string,
  //   measurementsParamsValues: any[], measurementsParamsValuesHeading: string[][], detailedInfo: string, footer: string): jsPDF {
  //   let pdf = new jsPDF();
  //   pdf.addFileToVFS('Ubuntu-R-normal.ttf', base64Fonts.ubuntuRegular);
  //   pdf.addFont('Ubuntu-R-normal.ttf', 'Ubuntu-R', 'normal');
  //   pdf.setFont('Ubuntu-R');
  //   pdf.setFontSize(18);

  //   var splitTitle = pdf.splitTextToSize(header, 180);
  //   pdf.text(splitTitle, 11, 8);

  //   pdf.setFontSize(12);
  //   pdf.setTextColor(99);
  //     //table 1
  //   (pdf as any).autoTable({
  //     head: [["", ""]],
  //     body: measurementParams,
  //     margin: { top: 15 },
  //     styles: { font: 'Ubuntu-R' },
  //     theme: 'striped',
  //   });

  //   let finalY = pdf.lastAutoTable.finalY;
  //   var splitTitle = pdf.splitTextToSize(measurementsHeader, 180);
  //   pdf.text(splitTitle, 15, finalY + 5);
  //   //table 2
  //   (pdf as any).autoTable({
  //     head: measurementsHeading,
  //     body: measurements,
  //     margin: { top: 5 },
  //     styles: { font: 'Ubuntu-R' },
  //     theme: 'striped',
  //   });

  //   finalY = pdf.lastAutoTable.finalY;
  //   var splitTitle = pdf.splitTextToSize(measurementsParamsValuesHeader, 180);
  //   pdf.text(splitTitle, 15, finalY + 5);
  //   //table 3
  //   (pdf as any).autoTable({
  //     head: measurementsParamsValuesHeading,
  //     body: measurementsParamsValues,
  //     margin: { top: 5 },
  //     styles: { font: 'Ubuntu-R' },
  //     theme: 'striped',
  //   });

  //   pdf.addPage();
  //   var splitTitle = pdf.splitTextToSize(detailedInfo, 180);
  //   pdf.text(splitTitle, 11, 8);
    
  //   body.forEach((val, index, array) => {
  //     (pdf as any).autoTable({
  //       head: heading,
  //       body: val,
  //       margin: { top: 10 },
  //       styles: { font: 'Ubuntu-R' },
  //       theme: 'striped',
  //     });

  //     if (index !== array.length - 1){ 
  //       pdf.addPage();
  //     }
  //  });

  //  pdf = this.addFooters(pdf, footer);

  //   return pdf;
  // }

  // addFooters(doc: jsPDF, footer: string): jsPDF{
  //   const pageCount = doc.internal.getNumberOfPages();
  //   for(var i = 1; i <= pageCount; i++) {
  //     doc.setPage(i);
  //     doc.text(footer, 90, 285);
  //   }

  //   return doc;
  // }
 
}