<h1 translate>{{certified === 'true' ? 'home.certifiedLoopMode' : 'menu.loopMode'}}</h1>
<form #loopForm="ngForm" class="loop-form" (ngSubmit)="onLoopMode()" *ngIf="!loopStarted">
   <div *ngIf="!hasEmail">
       <div>
           <label for="email" translate>{{certified === 'true' ? 'loopTest.certifiedInsertEmail' : 'loopTest.insertEmail'}}</label>
           <div>
               <input #emailInput="ngModel" type="text" name="email" id="email" ngModel required email [(ngModel)]="email" pattern=".+@.+\.[a-z]+"
               minlength="5" maxlength="64">
               <mat-icon class="hint" [matTooltip]="selectedInfoLabel.text" *ngIf="selectedInfoLabel">info</mat-icon>
               <!-- <div *ngIf="emailInput.touched && emailInput.errors?.required" class="validation-error" translate="">loopTest.requiredEmail</div>
                <div *ngIf="emailInput.touched && emailInput.errors?.email" class="validation-error" translate="">loopTest.invalidEmail</div> -->
                <div [hidden]="emailInput.valid || emailInput.pristine" class="validation-error" translate>loopTest.invalidEmail</div>
            </div>
            <label *ngIf="certified === 'true'" style="color:red" translate>loopTest.certifiedRemark</label>
    </div>
    <div>
        <button class="loop-btn start-loop-btn" type="button" (click)="sendEmail()" [disabled]="loopForm.invalid || !termsAccepted" translate>{{certified === 'true' ? 'home.certifiedLoopMode' : 'loopTest.startTest'}}</button>
    </div>
   </div>
  
    <div *ngIf="hasEmail">   
        <div>
            <label for="cycle" translate>loopTest.chooseLoopType</label>
            <div>
                <select id="cycle" name="cycle" [(ngModel)]="selectedCycle">
                    <option *ngFor="let cycle of measurementCycles" [ngValue]="cycle"
                     translate [translateParams]="{testsNumber: cycle.loopCount, timeBetween: cycle.timeBetween}">
                            loop.configurationLabel
                    </option>
                </select>
                <mat-icon class="hint" [matTooltip]="additionalSelectedInfoLabel.text" *ngIf="additionalSelectedInfoLabel">info</mat-icon>
            </div>
        </div>
        <div >
            <button class="loop-btn start-loop-btn" type="submit" [disabled]="!selectedCycle" translate>{{certified === 'true' ? 'home.certifiedLoopMode' : 'loopTest.startTest'}}</button>
        </div>
    </div>
</form>
<div *ngIf="loopStarted">
    <label translate [translateParams]="{current: currentTest, total: selectedCycle.loopCount}">loopTest.testCounter</label>
    <div style="height: 2em; width: 100%;">
        <div class="progress" *ngIf="testStarted">
            <div id="testprogress" class="progress-bar" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style="min-width: 3em; width: 0%;">0 %</div>
        </div>
        <div *ngIf="countdownStarted" [ngStyle]="{'width':width+'%', 'background-color':'green', 'min-width':'5%', 'text-align': 'center'}">
            <countdown #cd [config]="countdownConfig" (event)="onNotifyEvent($event)"></countdown>
        </div>
    </div>

    <div id="infocurrent">
        <div class="next-row">
            <div class="first-column" translate>details.status</div>
            <div class="second-column" id="infostatus" translate>-</div>
        <div class="next-row">
            <div class="first-column" translate>loopTest.ping</div>
            <div class="second-column" id="infoping"><div class="loader" style="display: none;"></div> <span>-</span></div>
            <div class="first-column" translate>loopTest.download</div>
            <div class="second-column" id="infodown"><div class="loader" style="display: none;"></div> <span>-</span></div>
            <div class="first-column" translate>loopTest.upload</div>
            <div class="second-column" id="infoup"><div class="loader" style="display: none;"></div> <span>-</span></div>
        </div>
        <div class="next-row">
            <div class="first-column" translate>loopTest.coordinates</div>
            <div class="second-column" id="infogeo">-</div>
            <div class="first-column" translate>loopTest.ip</div>
            <div class="second-column" id="infoip">-</div>
            <!-- <div class="first-column" translate>loopTest.operator</div>
            <div class="second-column" id="infoprovider">-</div> -->
            <div class="first-column" translate>details.publicIPAsName</div>
            <div class="second-column" id="infoasname">-</div>
        </div>
    </div>
    <div width="100%" style="margin-bottom: 20pt;">
        <h2 style="float: left;" translate>loopTest.lastMeasurements</h2>
        <button mat-fab color="primary" style="float: right;" [matMenuTriggerFor]="exportMenu"
            matTooltip="{{ 'export' | translate }}" *ngIf="loopFinished">
            <mat-icon>download</mat-icon>
        </button>

        <mat-menu #exportMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="exportToExcel()">
                <mat-icon>view_list</mat-icon>
                <span>XLSX</span>
              </button>
              <button mat-menu-item (click)="exportToPdf()">
                <mat-icon>picture_as_pdf</mat-icon>
                <span>PDF</span>
              </button>
              <button mat-menu-item (click)="exportToCsv()">
                <mat-icon>article</mat-icon>
                <span>CSV</span>
              </button>
        </mat-menu>
        
    </div>
    <table class="table" mat-table [dataSource]="dataSource" *ngIf="lastTests.length > 0">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
          <th mat-header-cell *matHeaderCellDef translate>{{ column.header }}</th>
          <td mat-cell *matCellDef="let row">
            <label *ngIf="canChangeVisibility(column.columnDef)">{{ getValue(row[column.columnDef]) }}</label>
            <label *ngIf="!canChangeVisibility(column.columnDef) && column.columnDef !== 'status'">{{ row[column.columnDef] }}</label>
            <label *ngIf="!canChangeVisibility(column.columnDef) && column.columnDef === 'status'">{{'measurements.test_status.' + row[column.columnDef] | translate}}</label>
          </td>
          <td mat-footer-cell *matFooterCellDef>
                <label *ngIf="column.columnDef === 'speedDownload'">{{getAverageDownload()}}</label>
                <label *ngIf="column.columnDef === 'speedUpload'">{{getAverageUpload()}}</label>
                <label *ngIf="column.columnDef === 'ping'">{{getAveragePing()}}</label>
                <label *ngIf="column.columnDef === 'packetLoss'">{{getAveragePacketLoss()}}</label>
            <label *ngIf="column.columnDef === 'orderNum'" translate>loopTest.average</label> 
         </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
</div>
