<h1 translate>home.history</h1>
<div>
  <div *ngIf="measurement" class="mat-table-container">
    <div width="100%" style="margin-bottom: 20pt;">
        <button mat-fab color="primary" style="float: right;" [matMenuTriggerFor]="exportMenu"
            matTooltip="{{ 'export' | translate }}">
            <mat-icon>download</mat-icon>
        </button>

        <mat-menu #exportMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="exportToExcel()">
                <mat-icon>view_list</mat-icon>
                <span>XLSX</span>
              </button>
              <button mat-menu-item (click)="exportToPdf()">
                <mat-icon>picture_as_pdf</mat-icon>
                <span>PDF</span>
              </button>
              <button mat-menu-item (click)="exportToCsv()">
                <mat-icon>article</mat-icon>
                <span>CSV</span>
              </button>
        </mat-menu>
        
    </div>
    <h2 translate>history.resultFrom</h2>
    <table class="mat-table">
        <tr class="mat-row">
            <td class="mat-cell-label" translate>details.testTime</td>
            <td>{{measurement.time}}</td>
        </tr>
        <tr class="mat-row">
            <td class="mat-cell-label" translate>details.status</td>
            <td>{{'measurements.test_status.' + measurement.status | translate}}</td>
        </tr>
        <tr class="mat-row" *ngIf="measurement.errorType">
            <td class="mat-cell-label" translate>details.errorType</td>
            <td>{{'measurements.errorType.' + measurement.errorType | translate}}</td>
        </tr>
    </table>
    <h2 class="inner-title" translate>details.params</h2>
    <table class="mat-table">
        <tr class="mat-row" *ngIf="measurement.speedDownload">
            <td class="mat-cell-label" translate>details.downloadSpeed</td>
            <td>{{measurement.speedDownload}} Mbps</td>
        </tr>
        <tr class="mat-row" *ngIf="measurement.speedUpload">
            <td class="mat-cell-label" translate>details.uploadSpeed</td>
            <td>{{measurement.speedUpload}} Mbps</td>
        </tr>
        <tr class="mat-row" *ngIf="measurement.ping">
            <td class="mat-cell-label" translate>details.ping</td>
            <td>{{measurement.ping}} ms</td>
        </tr>
        <tr class="mat-row" *ngIf="measurement.packetLoss >= 0">
            <td class="mat-cell-label" translate>details.packetLoss</td>
            <td>{{measurement.packetLoss}} </td>
        </tr>
        <tr class="mat-row" *ngIf="measurement.signalStrength">
            <td class="mat-cell-label" translate>details.signal</td>
            <td>{{measurement.signalStrength}} dBm</td>
        </tr>
        <!-- <tr class="mat-row" *ngIf="measurement.blockedPorts && getParamVisibilityByCode('blocked_ports')" >
            <td class="mat-cell-label" translate>details.blockedPorts</td>
            <td>{{measurement.blockedPorts}} </td>
        </tr> -->
    </table>
    <h2 class="inner-title" translate>details.info</h2>
    <table class="mat-table">
      <tr class="mat-row">
          <td class="mat-cell-label" translate>details.measurementCode</td>
          <td translate>{{measurement.code}}</td>
      </tr>
      <tr class="mat-row" *ngIf="measurement.loopMode !== undefined">
          <td class="mat-cell-label" translate>details.measurementPrefix</td>
          <td>{{measurement.loopMode.loopModeLoopUid}}</td>
      </tr>
      <!-- <tr class="mat-row" *ngIf="measurement.loopMode !== undefined">
          <td class="mat-cell-label" translate>details.measurementId</td>
          <td>{{measurement.loopMode.loopModeUid}}</td>
      </tr> -->
      <tr class="mat-row">
          <td class="mat-cell-label" translate>details.ip</td>
          <td>{{measurement.ip}}.*</td>
      </tr>
      <tr class="mat-row">
          <td class="mat-cell-label" translate>details.position</td>
          <td>{{measurement.geolocation}}</td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell-label" translate>details.city</td>
        <td>{{getCityName()}}</td>
       </tr>
       <tr class="mat-row">
        <td class="mat-cell-label" translate>details.region</td>
        <td>{{getRegionName()}}</td>
       </tr>
      <!-- <tr class="mat-row">
          <td class="mat-cell-label" translate>details.provider</td>
          <td>{{measurement.provider}}</td>
      </tr> -->
      <tr class="mat-row">
        <td class="mat-cell-label" translate>details.publicIPAsName</td>
        <td>{{measurement.publicIPAsName}}</td>
    </tr>
      <tr class="mat-row">
          <td class="mat-cell-label" translate>details.networkType</td>
          <td>{{measurement.networkType}}</td>
      </tr>
      <tr class="mat-row">
          <td class="mat-cell-label" translate>details.softwareName</td>
          <td>{{measurement.clientName}}</td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell-label" translate>details.softwareVersion</td>
        <td>{{measurement.clientSoftwareVersion}}</td>
      </tr>
      <tr class="mat-row">
          <td class="mat-cell-label" translate>details.model</td>
          <td>{{measurement.model}}</td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell-label" translate>details.browserVersion</td>
        <td>{{measurement.modelVersion}}</td>
     </tr>
     <!-- <tr class="mat-row">
        <td class="mat-cell-label" translate>details.imei</td>
        <td>{{measurement.imei}}</td>
     </tr> -->
      <!-- <tr class="mat-row">
          <td class="mat-cell-label" translate>details.hostname</td>
          <td>{{measurement.hostname}}</td>
      </tr> -->
      <tr class="mat-row">
          <td class="mat-cell-label" translate>details.duration</td>
          <td>{{measurement.testDuration}} s</td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell-label" translate>details.testId</td>
        <td>{{measurement.testUuid}}</td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell-label" translate>details.clientId</td>
        <td>{{measurement.clientUuid}}</td>
      </tr>
     </table>
     <div class="mat-table-container" *ngIf="measurement.status !== 'unsuccessful'">
      <h2 class="inner-title" translate *ngIf="qos && qos.testresultdetail">details.qos</h2>
      <table class="mat-table">
            <!-- <tr class="mat-row" *ngIf="getParamVisibilityByCode('availability_of_connectivity') && getQOSData('transparent')?.containsItems()">
                <td class="mat-cell-label" translate>details.availability_of_connectivity</td>
                <td class="mat-cell"[innerHTML]="getQOSData('transparent')?.getItems()"></td>
            </tr> -->
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('availability_of_connectivity') && qos && qos.testresultdetail">
                <td class="mat-cell-label">{{ 'details.availability_of_connectivity' | translate }}</td>
                <td class="mat-cell"[innerHTML]="measurement.availabilityOfConn"></td>
            </tr>
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('dns') && getQOSData('dns')?.containsItems()">
                <td class="mat-cell-label" translate>details.dns</td>
                <td class="mat-cell"[innerHTML]="getQOSData('dns')?.getItems()"></td>
            </tr>
            <!-- <tr class="mat-row" *ngIf="getParamVisibilityByCode('proxy') && getQOSData('proxy')?.containsItems()">
                <td class="mat-cell-label" translate>details.proxy</td>
                <td class="mat-cell">
                    <mat-icon class="{{getQOSStatus(measurement.proxy)}}">{{getQOSStatus(measurement.proxy)}}</mat-icon>
                </td>
            </tr>
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('proxy') && getQOSData('proxy')?.containsItems()">
                <td class="mat-cell-label"></td>
                <td class="mat-cell"[innerHTML]="getQOSData('proxy')?.getItems()"></td>
            </tr> -->
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('proxy') && qos && qos.testresultdetail">
                <td class="mat-cell-label">{{ 'details.proxy' | translate }}</td>
                <td class="mat-cell">
                    <label>{{measurement.proxyCount}}</label>
                    <mat-icon class="{{getQOSStatus(measurement.proxy)}}">{{getQOSStatus(measurement.proxy)}}</mat-icon>
                </td>
            </tr>
            <!-- <tr class="mat-row" *ngIf="getParamVisibilityByCode('web') && getQOSData('web')?.containsItems()">
                <td class="mat-cell-label" translate>details.webPerformance</td>
                <td class="mat-cell">
                    <mat-icon class="{{getQOSStatus(measurement.webPerformance)}}">{{getQOSStatus(measurement.webPerformance)}}</mat-icon>
                </td>
            </tr>
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('web') && getQOSData('web')?.containsItems()">
                <td class="mat-cell-label"></td>
                <td class="mat-cell"[innerHTML]="getQOSData('web')?.getItems()"></td>
            </tr> -->
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('web') && qos && qos.testresultdetail">
                <td class="mat-cell-label">{{ 'details.webPerformance' | translate }}</td>
                <td class="mat-cell">
                    <label>{{measurement.webPerformanceCount}}</label>
                    <mat-icon class="{{getQOSStatus(measurement.webPerformance)}}">{{getQOSStatus(measurement.webPerformance)}}</mat-icon>
                </td>
            </tr>
            <!-- <tr class="mat-row" *ngIf="getParamVisibilityByCode('media') && getQOSData('media')?.containsItems()">
                <td class="mat-cell-label" translate>details.videoAndAudio</td>
                <td class="mat-cell">
                    <mat-icon class="{{getQOSStatus(measurement.videoAndAudio)}}">{{getQOSStatus(measurement.videoAndAudio)}}</mat-icon>
                </td>
            </tr>
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('media') && getQOSData('media')?.containsItems()">
                <td class="mat-cell-label"></td>
                <td class="mat-cell"[innerHTML]="getQOSData('media')?.getItems()"></td>
            </tr> -->
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('media') && qos && qos.testresultdetail">
                <td class="mat-cell-label">{{ 'details.videoAndAudio' | translate }}</td>
                <td class="mat-cell">
                    <label>{{measurement.videoAndAudioCount}}</label>
                    <mat-icon class="{{getQOSStatus(measurement.videoAndAudio)}}">{{getQOSStatus(measurement.videoAndAudio)}}</mat-icon>
                </td>
            </tr>
            <!-- <tr class="mat-row" *ngIf="getParamVisibilityByCode('voip') && getQOSData('voip')?.containsItems()">
                <td class="mat-cell-label" translate>details.voip</td>
                <td class="mat-cell">
                    <mat-icon class="{{getQOSStatus(measurement.voip)}}">{{getQOSStatus(measurement.voip)}}</mat-icon>
                </td>
            </tr>
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('voip') && getQOSData('voip')?.containsItems()">
                <td class="mat-cell-label"></td>
                <td class="mat-cell"[innerHTML]="getQOSData('voip')?.getItems()"></td>
            </tr> -->
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('voip') && qos && qos.testresultdetail">
                <td class="mat-cell-label">{{ 'details.voip' | translate }}</td>
                <td class="mat-cell">
                    <label>{{measurement.voipCount}}</label>
                    <mat-icon class="{{getQOSStatus(measurement.voip)}}">{{getQOSStatus(measurement.voip)}}</mat-icon>
                </td>
            </tr>

            <!-- <tr class="mat-row" *ngIf="getParamVisibilityByCode('traceroute') && getTracerouteHops()">
                <td class="mat-cell-label" translate>details.traceroute</td>
                <td class="mat-cell">{{getTracerouteHops()}}</td>
            </tr> -->
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('traceroute') && getTracerouteHops()">
                <td class="mat-cell-label">{{ 'details.traceroute' | translate }}</td>
                <td class="mat-cell">{{measurement.tracerouteCount}}</td>
            </tr>
            <!-- <tr class="mat-row" *ngIf="getParamVisibilityByCode('blocked_ports') && getQOSData('ports')?.containsItems()">
                <td class="mat-cell-label" translate>details.blockedPorts</td>
                <td class="mat-cell"[innerHTML]="getQOSData('ports')?.getItems()"></td>
            </tr> -->
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('blocked_ports_udp') && getQOSData('udp')?.containsItems()">
                <td class="mat-cell-label">{{ 'details.blockedPorts' | translate }} - UDP</td>
                <td class="mat-cell"[innerHTML]="getQOSData('udp')?.getItems()"></td>
            </tr>
            <tr class="mat-row" *ngIf="getParamVisibilityByCode('blocked_ports_tcp') && getQOSData('tcp')?.containsItems()">
                <td class="mat-cell-label">{{ 'details.blockedPorts' | translate }} - TCP</td>
                <td class="mat-cell"[innerHTML]="getQOSData('tcp')?.getItems()"></td>
            </tr>
      </table>
  </div>
  </div>
  <h2 class="inner-title" translate>details.position</h2>
  <div class="map-container">
    <div class="map-frame">
        <div id="map"
        leaflet
        (leafletMapReady)="onMapReady($event)"
        [leafletOptions]="mapOptions"></div>
    </div>
  </div>
  <h1 translate>details.speedCurve</h1>
  <div *ngIf="measurement && measurement.speedDownload">
  <h2 translate>details.download</h2>
    <canvas height="80"
      baseChart
      [chartType]="'line'"
      [datasets]="downloadData"
      [labels]="downloadChartLabels"
      [options]="chartOptions"
      [legend]="false">
    </canvas>
  </div>
  <div *ngIf="measurement && measurement.speedUpload">
    <h2 translate>details.upload</h2>
    <canvas height="80"
      baseChart
      [chartType]="'line'"
      [datasets]="uploadData"
      [labels]="uploadChartLabels"
      [options]="chartOptions"
      [legend]="false">
    </canvas>
  </div>
  <div *ngIf="measurement && measurement.ping">
  <h2 translate>details.ping</h2>
  <canvas height="80"
      baseChart
      [chartType]="'bar'"
      [data]="pingBarChartData"
      [labels]="pingBarChartLabels"
      [options]="pingBarOptions"
      [legend]="false">
    </canvas>
  </div>
</div>