export const config = {
   //Test
 // BASE_WS_URL: 'https://test-rmbt.infosys.bg/crc-admin-ws',
 // baseAddress: "https://test-rmbt.infosys.bg",
 //   adminPanel: "https://test-rmbt.infosys.bg/admin",	
  //Production
  BASE_WS_URL: 'https://nettest.crc.bg/crc-admin-ws',
  baseAddress: "https://nettest.crc.bg",
  adminPanel: "https://nettest.crc.bg/admin",
  controlServerPath: "/RMBTControlServer",
  mapServerTiles: '/RMBTMapServer/tiles/',
  mapRequestParams: '/{z}/{x}/{y}.png?statistical_method=0.5&map_options=all/download',
  export: "/export/",
  openTestsPath: "/opentests",
  historyPath: "/history",
  testResultDetailPath: "/testresultdetail",
  public: "/public",
  measurements: '/measurements',
  measurementCycles: '/measurement-cycles',
  network_groups: '/network-groups',
  network_type_groups: '/network-type-groups',
  speeds: '/speeds',
  providers: '/providers',
  regions: '/regions',
  all:    "/all",
  filterPublicMap: "/filter-public-map",
  add:    "/add",
  settings: "/settings",
  webAppLabels: '/web-app-labels',
  qos: '/qos/',
  paramVisibility: "/param-visibility",
  timestamp: "/timestamp",
  loopUuid: '/uuid',
  history: '/history',
  timeout: 20000,
  uuid_cookie_name: "uuid",
  terms_cookie_name: "CrcTerms",
  infoCode: 'INFO',
  privacyPolicyCode: 'PRIVACY_POLICY',
  termsOfUseCode: 'TERMS_OF_USE',
  faq: 'FAQ',
  hintHistoryCode: 'HINT_HISTORY',
  hintLoopCode: 'HINT_LOOP',
  hintLoopCyclesCode: 'HINT_LOOP_CYCLES',
  hintSingleTestCode: 'HINT_SINGLE_TEST',
  hintMapCode: 'HINT_MAP',
  exdays: 365*24*60*60,
  snackbarDuration: 10,

  pageSizeOptions: [5, 25, 50],
  filterAll: 'Всички/ All',

};

export const urls = {
  loop: config.BASE_WS_URL + '/loop',
  loopMode: '/loop-mode',
  loopMeasurement: '/loop-measurement',
  updateLoopMeasurement: '/update-loop-measurement',
  historyExport: config.BASE_WS_URL + config.public + '/history-export',
  markerInfo: config.baseAddress + config.mapServerTiles + 'markers',
  heatLayer: config.baseAddress + config.mapServerTiles + 'heatmap' + config.mapRequestParams,
  pointsLayer: config.baseAddress + config.mapServerTiles + 'points' + config.mapRequestParams,
  googlePlay: 'https://play.google.com/store/apps/details?id=bg.infosys.crc.networkquality',
  appStore: 'https://apps.apple.com/bg/app/crc-network-quality/id1578184942'
};

export const chartConfig ={
  lat:42.65167376467343,
  long:23.405688234571674,
  chartOptions : {
      responsive: true,
      // elements: {
      //   line: {
      //     fill:false
      //   }
      // },
      scales: {
        yAxes: [{
          ticks: {
            callback: function(value, index, values) {
              return value + ' Mbps';
          }
          }
        }],
        xAxes: [{
          ticks: {
            callback: function(value, index, values) {
              return value + ' s';
          }
          }
        }]
      }
    },

  downloadBackgroundColor: '#008000',
  uploadBackgroundColor: '#6db1f6',

  pingBarOptions : {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          callback: function(value, index, values) {
            return value + ' Ms';
        }
        }
      }],
      xAxes: [{
        ticks: {
          callback: function(value, index, values) {
            return value + ' s';
        }
        }
      }]
    }
  },
}
