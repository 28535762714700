import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  path: string;
  prefix: string = ' ';
  selectedLang: string;
  
  
  constructor(public translate: TranslateService, private router: Router,  private route: ActivatedRoute) {
    //detect if lang is changed
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getPath();
    });
    //detect router events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.getPath();
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem("lang") != null) {
      this.translate.use(localStorage.getItem("lang"));
      this.selectedLang = localStorage.getItem("lang").toUpperCase();
    }
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    this.selectedLang = lang.toUpperCase();
  }

  getPath() {
    this.path = this.router.url.split('?')[0];
    switch (this.path) {
      case '/home':
        this.prefix = ' ';
        break;

      case '/loop':
        this.route.queryParams.subscribe(params => {
          if (params['certified'] && 'false' === params['certified']) {
            this.translate.get('home.loopMode').subscribe(() => {
              this.prefix = ' > ' + this.translate.instant('home.loopMode');
            })
          } else {
            this.translate.get('home.certifiedLoopMode').subscribe(() => {
              this.prefix = ' > ' + this.translate.instant('home.certifiedLoopMode');
            })
          }
       });
        break;

      case '/statistics':
        this.translate.get('header.statistics').subscribe(() => {
          this.prefix = ' > ' + this.translate.instant('header.statistics');
        })
        break;

      case '/map':
        this.translate.get('home.map').subscribe(() => {
          this.prefix = ' > ' + this.translate.instant('home.map');
        })
        break;

      case '/history':
        this.translate.get('home.history').subscribe(() => {
          this.prefix = ' > ' + this.translate.instant('home.history');
        })
        break;

      case '/history/detailed-result':
        this.translate.get('history.resultFrom').subscribe(() => {
          this.prefix = ' > ' + this.translate.instant('history.resultFrom');
        })
        break;

      case '/test':
        this.translate.get('header.startTest').subscribe(() => {
          this.prefix = ' > ' + this.translate.instant('header.startTest');
        })
        break;

      case '/information':
        this.translate.get('home.Information').subscribe(() => {
          this.prefix = ' > ' + this.translate.instant('home.Information');
        })
        break;

      case '/search':
        this.translate.get('home.openData').subscribe(() => {
          this.prefix = ' > ' + this.translate.instant('home.openData');
        })
        break;

        case '/privacy-policy':
        this.translate.get('home.privacyPolicy').subscribe(() => {
          this.prefix = ' > ' + this.translate.instant('home.privacyPolicy');
        })
        break;

      default:
        break;
    }
  }

}
