import { Component, OnInit } from '@angular/core';
import { config } from '../../../../environments/config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

  redirectToAdminPanel() {
    //window.location.href = "http://localhost:4200";
    window.location.href = config.adminPanel;
  }
}
