import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractWebAppLabelComponent } from '@components/abstract/abstract-web-app-label-component';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InformationService } from '@services/information.service';
import { config } from '@env/config';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent extends AbstractWebAppLabelComponent implements OnInit {

  constructor(
    protected _changeDetectorRef: ChangeDetectorRef, 
    protected translate: TranslateService, 
    protected sanitizer: DomSanitizer,
    protected informationService: InformationService) {
          super(_changeDetectorRef, translate, informationService, sanitizer, config.faq);
   }

  ngOnInit(): void {
  }

}
