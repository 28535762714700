import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortcutElementComponent } from '@components/shared/shortcut-element/shortcut-element.component';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { MatGridListModule } from '@angular/material/grid-list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    ShortcutElementComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    CdkTableModule,
    MatGridListModule,
    FormsModule, 
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
  ],
  exports: [
    ShortcutElementComponent
  ]
})
export class SharedModule { }
