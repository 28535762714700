<div class="footer-container">
    <div class="footer-container-content main-container">
        <div class="qr-code-container">
            <table>
                <tr>
                    <td class="barcode-cell">
                        <div>
                            <qrcode class="barcode-shadow" [qrdata]="googlePlay" [width]="128" [errorCorrectionLevel]="'M'"></qrcode>
                            <img alt="Logo" src="./assets/img/googlePlay.png" class="app-store-logo" (click)="redirectToGooglePlay()">
                        </div>
                    </td>
                    <td>
                        <div>
                            <ul class="nav">
                                <!-- <li routerLink="/statistics" translate>header.statistics</li>   -->
                                <!-- <li routerLink="/information" translate>home.Information</li> -->
                                <li routerLink="/search" translate>home.openData</li>
                                <li routerLink="/privacy-policy" translate>home.privacyPolicy</li>
                                <li routerLink="/terms-of-use" translate>home.termsOfUse</li>
                                <li routerLink="/faq" translate>home.FAQ</li>
                            </ul>
                        </div>
                    </td>
                    <td class="barcode-cell">
                        <qrcode class="barcode-shadow" [qrdata]="appStore" [width]="128" [errorCorrectionLevel]="'M'"></qrcode>
                        <img alt="Logo" src="./assets/img/appStore.png" class="app-store-logo" (click)="redirectToAppSore()">
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="project-info" *ngIf="visible">
        <div class="footer-container-content project-info-container">
                <div class="footer-text footer-div">
                   <p class="text" translate>footer.text</p>
                </div>
                <div class="footer-logos footer-div">
                    <img alt="Logo" src="./assets/img/logosEuOp.png" class="eu-logo"/>
                </div>
                <div class="close" (click)="visible = false" >x</div>
        </div>
    </div>
</div>