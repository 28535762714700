import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { config } from '@env/config';
import { Provider } from '@models/provider';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  
  constructor(private http: HttpClient) {
  }

  findAll(): Observable<Provider[]> {
    return this.http.get<Provider[]>(config.BASE_WS_URL + config.public + config.providers)
        .pipe(
            timeout(config.timeout)
        );
    }
}