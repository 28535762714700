import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { LoopMode } from '@models/loop-mode';
import { MeasurementCycle } from '@models/measurement-cycle';
import { urls, config } from '@env/config';
import { LoopMeasurement } from '@models/loop-measurement';

const requestOptions: Object = {
  responseType: 'text'
}

@Injectable({providedIn: 'root'})
export class LoopTestService {

  protected httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

  constructor(
      private http: HttpClient
    ) { }

  public getMeasurementCycles(certified: string): Observable<MeasurementCycle[]> {
    let requestParams = new HttpParams();
    requestParams = requestParams.set("certified", certified);

    return this.http.get<MeasurementCycle[]>(config.BASE_WS_URL + config.public + config.measurementCycles, {
      params: requestParams
        }).pipe(timeout(config.timeout));
  }

  addLoopMode(entity: LoopMode): Observable<any> {
    return this.http.post(`${urls.loop + urls.loopMode}`, entity, this.httpOptions).pipe(
      timeout(config.timeout)
    );
  }

  addLoopMeasurement(entity: LoopMeasurement): Observable<any> {
    return this.http.post(`${urls.loop + urls.loopMeasurement}`, entity, this.httpOptions).pipe(
      timeout(config.timeout)
    );
  }

  updateLoopMeasurement(entity: LoopMeasurement): Observable<any> {
    return this.http.put(`${urls.loop + urls.updateLoopMeasurement}`, entity, this.httpOptions).pipe(
      timeout(config.timeout)
    );
  }

  public getLoopUuid(): Observable<string> {
    return this.http.get<string>(urls.loop + config.loopUuid, requestOptions).pipe(timeout(config.timeout));
  }

}