import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { config } from '@env/config';
import { InformationService } from '@services/information.service';
import { AbstractWebAppLabelComponent } from '@components/abstract/abstract-web-app-label-component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent extends AbstractWebAppLabelComponent implements OnInit {

  constructor(
    protected _changeDetectorRef: ChangeDetectorRef, 
    protected translate: TranslateService, 
    protected sanitizer: DomSanitizer,
    protected informationService: InformationService) {
          super(_changeDetectorRef, translate, informationService, sanitizer, config.infoCode);
   }

  ngOnInit(): void {
  }

}
