export class MeasurementFilter {
    uid?: number;
    uuid?: string;

    uploadSpeedId?: number;
    downloadSpeedId?: number;

    networkType?: string;
    providerId?: number;
    regionCodes?: string[];

    periodFrom?: Date;
    periodTo?: Date;

    constructor() {
        this.clear();
    }

    clear() {
        this.uid = undefined;
        this.uuid = '';

        this.uploadSpeedId = undefined;
        this.downloadSpeedId = undefined;

        this.networkType = undefined;
        this.providerId = undefined;
        this.regionCodes = [];

        this.periodFrom = undefined;
        this.periodTo = undefined;
    }

    copyFilter(newFilter: MeasurementFilter) {

        this.uuid = newFilter.uuid;
        this.periodFrom = newFilter.periodFrom;
        this.periodTo = newFilter.periodTo;
    }
}