import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router";
import { NgForm } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TranslateService } from '@ngx-translate/core';

import { AbstractTestComponent } from '@components/abstract/abstract-test-component';
import { config, urls } from '@env/config';
import { MeasurementService } from '@services/measurement.service';
import { SettingsService } from '@services/settings.service';
import { YnDialogComponent } from '../yn-dialog/yn-dialog.component';
import { InformationService } from '@services/information.service';
import { DomSanitizer } from '@angular/platform-browser';

declare const TestEnvironment: any;
declare const RMBTTestConfig: any;
declare const DemoTestVisualization: any;
declare const RMBTControlServerCommunication: any;
declare const RMBTTest: any;


@Component({
  selector: 'app-single-test',
  templateUrl: './single-test.component.html',
  styleUrls: ['./single-test.component.css']
})
export class SingleTestComponent extends AbstractTestComponent implements OnInit {

  @ViewChild('form') form: NgForm;
  routePath = "history/detailed-result";
  test_uuid: number;
  testStarted: boolean;
  email: string;
  mobile: boolean;
  android: boolean;
  ios: boolean;
  interval: any;

  constructor(
    private router: Router,
    private measurementService: MeasurementService,
    protected settingsService: SettingsService,
    protected dialog: MatDialog,
    protected translate: TranslateService,
    protected snackBar: MatSnackBar,
    protected _changeDetectorRef: ChangeDetectorRef, 
    protected informationService: InformationService,
    protected sanitizer: DomSanitizer
    ) {
      super(snackBar, settingsService, dialog, translate, _changeDetectorRef, informationService, sanitizer, config.hintSingleTestCode)
   }

  ngOnInit(): void {
    this.mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (this.mobile) {
      this.android = /Android/i.test(navigator.userAgent);
      this.ios = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  
      if (this.android) {
        this.router.navigate(['home']);
        document.location.href = urls.googlePlay;
      }
  
      if (this.ios) {
        this.router.navigate(['home']);
        document.location.href = urls.appStore;
      }
    }
  };

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  redirectToDetails() {
    let count = 60;
    this.interval = setInterval(() => {
      if (navigator.onLine) {
        if (this.test_uuid) {
          if (this.email) {
            this.measurementService.changeEmail(this.email);
          }
          this.router.navigate([this.routePath], { queryParams:
            { id: this.test_uuid}
           });
        }
        clearInterval(this.interval);
      }
      count--;
      if (count < 1) {
        clearInterval(this.interval);
        this.openSnackBar(this.translate.instant("messages.disconnection"), this.translate.instant("close"));
      }
      
    }, 1000);


    // if (navigator.onLine) {
    //   if (this.test_uuid) {
    //     if (this.email) {
    //       this.measurementService.changeEmail(this.email);
    //     }
    //     this.router.navigate([this.routePath], { queryParams:
    //       { id: this.test_uuid}
    //      });
    //   }
    // } else {
    //   this.openSnackBar(this.translate.instant("messages.disconnection"), this.translate.instant("close"));
    // }
  }

  onStartTest() {
    if (this.clientUUID) {
      const dialogRef = this.dialog.open(YnDialogComponent, {
        data: {dialogTitle: this.translate.instant("messages.pleaseConfirm"), dialogText: this.translate.instant("messages.confirmLocationAndIP")}
      });
      
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.testStarted = true;
          TestEnvironment.init(new DemoTestVisualization((success) =>{
            this.test_uuid =success["testUUID"];
            this.redirectToDetails();
          },(error) =>{
            this.test_uuid =error["testUUID"];
            this.redirectToDetails();
            
          }), null);
          var testConfig = new RMBTTestConfig("en", config.baseAddress, "RMBTControlServer");
          var ctrl = new RMBTControlServerCommunication(testConfig);
      
          testConfig.uuid = this.clientUUID;
          testConfig.email = this.email;
      
          var websocketTest = new RMBTTest(testConfig, ctrl);
      
          TestEnvironment.getTestVisualization().setRMBTTest(websocketTest);
          TestEnvironment.getTestVisualization().startTest(); //start the visualization
      
          websocketTest.startTest();
        } else {
          this.openSnackBar(this.translate.instant("messages.measurementDenied"), this.translate.instant("close"));
        }
    });
    }
  }
}
