import { Component, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort, MatSort } from '@angular/material/sort';

import { AbstractTestComponent } from '@components/abstract/abstract-test-component';
import { HistoryService } from '@services/history.service';
import { SettingsService } from '@services/settings.service';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { InformationService } from '@services/information.service';
import { DomSanitizer } from '@angular/platform-browser';
import { config } from '@env/config';
import { MeasurementFilter } from '@models/measurement-filter';
import { DatePipe, Location } from '@angular/common';
import { SortingPaging } from '@app/util/sorting-paging';
import { MatPaginator } from '@angular/material/paginator';
import { Page } from '@app/util/page';
import { Measurement } from '@models/measurement';
import { ExportService } from '@services/export.service';

const routePath: string = "history/detailed-result";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HistoryComponent extends AbstractTestComponent implements OnInit {
  protected url: string;

  results: Measurement[];
  displayedColumns: string[];
  dataSource: MatTableDataSource<Measurement>;
  // @ViewChild(MatSort) sort: MatSort;
  selectedLang: string;
  filter = new MeasurementFilter();
  filterCopy = new MeasurementFilter();
  sortingPaging!: SortingPaging;
  pageSizeOptions: number[];
  @ViewChild(MatPaginator, {read: true}) paginator!: MatPaginator;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private historyService: HistoryService,
    private exportService: ExportService,
    protected location: Location,
    translate: TranslateService,
    snackBar: MatSnackBar, 
    settingsService: SettingsService,
    dialog: MatDialog,
    protected _changeDetectorRef: ChangeDetectorRef, 
    protected informationService: InformationService,
    protected sanitizer: DomSanitizer
  ) {
    super(snackBar, settingsService, dialog, translate, _changeDetectorRef, informationService, sanitizer, config.hintHistoryCode);
    // this.displayedColumns = ['time', 'provider', 'networkType', 'speedDownload', 'speedUpload', 'signalStrength', 'pingMedian', 'city'];
    this.displayedColumns = ['time', 'networkType', 'speedDownload', 'speedUpload', 'signalStrength', 'pingMedian', 'city'];
    // if (localStorage !== null && localStorage.getItem("lang") != null) {
    //   this.selectedLang = localStorage.getItem("lang")!.toUpperCase();
    // }
    this.url = config.history;
    this.pageSizeOptions = config.pageSizeOptions;
    this.clearFilters();
    this.sortingPaging = new SortingPaging();
    if (this.translate.currentLang) {
        this.selectedLang = this.translate.currentLang.toUpperCase();
    } else if (this.translate.defaultLang) {
        this.selectedLang = this.translate.defaultLang.toUpperCase();
    }
   }

  ngOnInit(): void {
    if (this.clientUUID && this.termsAccepted) {
      this.route
    .queryParams
    .subscribe(params => {
        this.validatePageParams(params);
      //   this.getAll();
        this.search()
    });



    //   this.historyService.getHistory(this.clientUUID).subscribe(res  => {
    //    for (let element in res.history) {  
    //      this.results.push(res.history[element]);
    //     }
    //     this.dataSource = new MatTableDataSource(this.results);
    //     this.dataSource.sort = this.sort;
    //  });
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    console.log(this.dataSource.paginator)
}

  search() {
    this.sortingPaging.pageIndex = 0;
    this.refresh();
  }

  pageChanged(event) {
    this.sortingPaging.pageIndex = event.pageIndex;
    this.sortingPaging.pageSize = event.pageSize;

    this.refresh();
  }

  sort(sort: Sort) {
    if (sort.active && sort.direction) {
        this.sortingPaging.setSortBy(sort.active);
        this.sortingPaging.setSortDirection(sort.direction);

        this.getAll();
    }
}

  getAll(): void {
    this.filter.uuid = this.clientUUID;
    this.filterCopy.copyFilter(this.filter);
    this.historyService.findHistoryPageable(this.sortingPaging, this.filter)
    .subscribe(response => {
      this.results = response.content;
      this.dataSource.data = this.results;

      // if (this.results.length === 0) {
      //   this.openSnackBar(this.translate.instant("messages.empty_paging"), this.translate.instant("close"));
      // }

      this.validateEmptyPage(response);
      this.sortingPaging.totalSize = response.totalElements;
    });
    this.dataSource = new MatTableDataSource(this.results);
  }

  clear(): void {
    // this.clearFilters();
    // this.search();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.url]);
  });
  }

  public clearFilters() {
    if (this.filter !== undefined) this.filter.clear();
    if (this.filterCopy !== undefined) this.filterCopy.clear();
  }

  protected validatePageParams(params) {
    if (params['from'] !== undefined) {
      this.filter.periodFrom = new Date(params['from']);
    }
    if (params['to'] !== undefined) {
      this.filter.periodTo =  new Date(params['to']);
    }
  }

  validateEmptyPage(page: Page<Measurement>) {
    if (this.sortingPaging.pageIndex != 0 && (page.pageNumber * page.pageSize) + 1 > page.totalElements) {
        this.sortingPaging.pageIndex = 0;

        this.refresh();
    }
  }

  private refresh() {
    this.getAll();
    const urlTree = this.router.createUrlTree([], {
        queryParams: this.getQueryParams(),
        queryParamsHandling: 'merge',
        preserveFragment: true 
    });
    this.location.go(urlTree.toString());
}

  protected getQueryParams(): Params {
    return {  from: this.filter.periodFrom !== undefined ? this.datepipe.transform(this.filter.periodFrom, 'yyyy-MM-dd')! : null,
              to: this.filter.periodTo !== undefined ? this.datepipe.transform(this.filter.periodTo, 'yyyy-MM-dd')! : null}
  }

  getCityName(test: Measurement): string {
    if (this.translate.currentLang) {
      this.selectedLang = this.translate.currentLang.toUpperCase();
    } else if (this.translate.defaultLang) {
      this.selectedLang = this.translate.defaultLang.toUpperCase();
    }

    if (this.selectedLang === 'BG') {
      return test.city;
    } else {
      return test.cityLat;
    }

    return '';
  }
  
  getRecord(test_uuid: number): void {
    this.router.navigate([routePath], { queryParams:
       { id: test_uuid}
      });
  }

  export(format: string): void {
    this.exportService.exportHistory(this.filterCopy, format, this.selectedLang, this.translate.instant("home.historyInfo"));
  }

  // sortData(sort: Sort) {
  //   const data = this.results.slice();
  //   if (!sort.active || sort.direction === '') {
  //     this.results = data;
  //     return;
  //   }

  //   this.results = data.sort((a, b) => {
  //     const isAsc = sort.direction === 'asc';
  //     switch (sort.active) {
  //       case 'time': return compare(a.time, b.time, isAsc);
  //       case 'provider': return compare(a.provider, b.provider, isAsc);
  //       case 'network_type': return compare(a.network_type, b.network_type, isAsc);
  //       case 'speed_download': return compare(a.speed_download, b.speed_download, isAsc);
  //       case 'speed_upload': return compare(a.speed_upload, b.speed_upload, isAsc);
  //       case 'signal_strength': return compare(a.signal_strength, b.signal_strength, isAsc);
  //       case 'ping': return compare(a.ping, b.ping, isAsc);
  //       case 'city': return compare(a.city, b.city, isAsc);
  //       default: return 0;
  //     }
  //   });
  // }

}
function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
