<h1 translate>header.history <mat-icon class="hint" [matTooltip]="selectedInfoLabel.text" *ngIf="selectedInfoLabel">info</mat-icon></h1> 


<div>
    <mat-expansion-panel expanded="true" style="width:100%">
        <!-- <mat-expansion-panel-header>
            <mat-panel-title class="filter-title" translate>
                filter.map
                <mat-icon class="hint" [matTooltip]="selectedInfoLabel.text" *ngIf="selectedInfoLabel">info</mat-icon>
            </mat-panel-title>
        </mat-expansion-panel-header> -->
        
        <form #filterForm="ngForm" appDateNotBefore>
            <div style="float: left;">
                <mat-form-field class="filter-field">
                    <mat-label translate>filter.periodFrom</mat-label>
                    <input matInput [matDatepicker]="pickerFrom" id="periodFrom" name="periodFrom" [(ngModel)]="filter.periodFrom">
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
    
                <mat-form-field class="filter-field">
                    <mat-label translate>filter.periodTo</mat-label>
                    <input matInput [matDatepicker]="pickerTo" id="periodTo" name="periodTo" [(ngModel)]="filter.periodTo">
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
    
            </div>
            <div class="filter-buttons">
                <button mat-stroked-button color="primary" (click)="clear()"><label translate>filter.clear</label></button>
                <button mat-raised-button color="primary" [disabled]="!filterForm.form.valid" (click)="search()"><label translate>filter.search</label></button>
            </div>
            <div class="export-buttons">
              <img src="./assets/img/export/xlsx.svg" width="40pt" height="40pt" class="export-button" (click)="export('xlsx')" />
              <img src="./assets/img/export/pdf.svg" width="40pt" height="40pt" class="export-button" (click)="export('pdf')" />
              <img src="./assets/img/export/csv.svg" width="40pt" height="40pt" class="export-button" (click)="export('csv')" />
            </div>
        </form>
    
        <!-- <mat-action-row class="filter-row">
            <div></div>
            <div>
                <button mat-stroked-button color="primary" (click)="clear()"><label translate>filter.clear</label></button>
                <button mat-raised-button color="primary" [disabled]="!filterForm.form.valid" (click)="search()"><label translate>filter.search</label></button>
            </div>
        </mat-action-row> -->
    </mat-expansion-panel>
  <!-- <table class="table" matSort (matSortChange)="sortData($event)">
    <tr>
        <th mat-sort-header="time" disabled (click)="sortByDate($event)"><label translate>history.date</label></th>
        <th mat-sort-header="provider"><label translate>history.provider</label></th>
        <th mat-sort-header="network_type"><label translate>history.networkType</label></th>
        <th mat-sort-header="speed_download"><label translate>history.downSpeed</label></th>
        <th mat-sort-header="speed_upload"><label translate>history.upSpeed</label></th>
        <th mat-sort-header="signal_strength"><label translate>history.signal</label></th>
        <th mat-sort-header="ping"><label translate>history.ping</label></th>
        <th mat-sort-header="city"><label translate>details.city</label></th>
    </tr>
    <tr class="mat-row" *ngFor="let result of results" (click)="getRecord(result.test_uuid)">
            <td>{{result.time | date : 'dd-MM-y hh:mm:ss'}}</td>
            <td>{{result.provider}}</td>
            <td>{{result.network_type}}</td>
            <td>{{result.speed_download}}</td>
            <td>{{result.speed_upload}}</td>
            <td>{{result.signal_strength}}</td>
            <td>{{result.ping}}</td>
            <td>{{result.city}}</td>
          </tr>
  </table> -->

  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sort($event)"
    matSortActive="uuid" matSortStart="asc" matSortDisableClear class="table">

    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header><label translate>history.date</label> </th>
      <!-- <td mat-cell *matCellDef="let result"> {{result.time | date : 'dd-MM-y HH:mm:ss':'UTC+3'}} </td> -->
      <td mat-cell *matCellDef="let result"> {{result.time}} </td>
    </ng-container>
  
    <!-- <ng-container matColumnDef="provider">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <label translate>history.provider</label> </th>
      <td mat-cell *matCellDef="let result"> {{result.provider}} </td>
    </ng-container> -->
  
    <ng-container matColumnDef="networkType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <label translate>history.networkType</label> </th>
      <td mat-cell *matCellDef="let result"> {{result.networkType}} </td>
    </ng-container>
  
    <ng-container matColumnDef="speedDownload">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <label translate>history.downSpeed</label> </th>
      <td mat-cell *matCellDef="let result"> {{result.speedDownload}} </td>
    </ng-container>

    <ng-container matColumnDef="speedUpload">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <label translate>history.upSpeed</label> </th>
      <td mat-cell *matCellDef="let result"> {{result.speedUpload}} </td>
    </ng-container>

    <ng-container matColumnDef="signalStrength">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <label translate>history.signal</label> </th>
      <td mat-cell *matCellDef="let result"> {{result.signalStrength}} </td>
    </ng-container>

    <ng-container matColumnDef="pingMedian">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <label translate>history.ping</label> </th>
      <td mat-cell *matCellDef="let result"> {{result.ping}} </td>
    </ng-container>

    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <label translate>details.city</label> </th>
      <td mat-cell *matCellDef="let result"> {{getCityName(result)}} </td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [colSpan]="displayedColumns.length">{{ 'messages.empty_paging' | translate }}</td>
  </tr>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="getRecord(row.test_uuid)"></tr>
  </table>
  <div style="margin-bottom: 25pt; min-width: 500pt;">
    <mat-paginator #paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="sortingPaging.totalSize"
        [pageIndex]="sortingPaging.pageIndex"
        [pageSize]="sortingPaging.pageSize"
        (page)="pageChanged($event)"
        showFirstLastButtons>
    </mat-paginator>
</div>
 </div>